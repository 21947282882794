
import './App.css';
import TopMenu from './componants/Menu/TopMenu';
import Customer from './componants/File/Screen/Customer'
import DashboardPage from './componants/Dashboard/DashboardPage';

function App() {
  return (
    <>
<TopMenu/>

   <DashboardPage/>
  
    </>
  );
}

export default App;
