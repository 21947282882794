import { useTheme } from "../../Utils/ThemeContaxt";
import React ,{useRef} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LocationListReduser } from "../../Reports/Reducer/LocationListReduser";
import { toTitleCase } from "../../Utils/UtilsFunction";

export function AddBoxModel(props) {
  const dispatch = useDispatch();
  const { apiLinks } = useTheme();

  const dscRef = useRef();

  const addLocation = () => {
    if (dscRef.current.value === "") {
      alert("Value is empty"); 
      return;
    }
    axios
      .post(`${apiLinks}/add-location`, {
        bx_dsc: dscRef.current.value,
        bx_sts:'Yes'
      })
      .then(function (response) {
        if (response.data.success) {
          dscRef.current.value = "";
          dispatch(LocationListReduser({ apiLinks }));
        } else {
          alert(response.data.message);
        }
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="account-maintenance-form">
          <Form.Group>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Form.Label>Box</Form.Label>
              </Col>

              <Col lg={12} md={12} sm={12}>
                <Form.Control
                  ref={dscRef}
                  autoComplete="none"
                  onChange={(e) => {
                    e.target.value = toTitleCase(e.target.value); // Convert input to uppercase
                  }}
                  placeholder="Box Number"
                />
              </Col>
            </Row>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ height: "60px" }}>
        <Button onClick={addLocation}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
