import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import axios from "axios";
import { useTheme } from "../../../Utils/ThemeContaxt";
import printer from "../../../../image/printer.png";
function ItemListModel(props) {
  const [itemsList, setItemsList] = useState([]);

  const { apiLinks } = useTheme();
  const getChartOfAccount = () => {
    axios
      .get(`${apiLinks}/items-list`)
      .then(function (response) {
        setItemsList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getChartOfAccount();
  }, []);

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      // style={customStyles}
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Item List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ overflowX: "auto", maxHeight: "530px", overflowY: "auto" }}
        >
          <Table style={{ width: "1080px" }} bordered hover className="mt-2">
  <thead className="table-header-color">
    <tr>
      <th
        style={{ width: "20px" }}
          className="table-hader-color table-hadear-height"
      >
        #
      </th>
      <th
        style={{ width: "100px" }}
           className="table-hader-color table-hadear-height"
      >
        Code
      </th>
      <th
        style={{ width: "300px" }}
           className="table-hader-color table-hadear-height"
      >
        Item Name
      </th>
      <th
        style={{ width: "120px" }}
           className="table-hader-color table-hadear-height"
      >
        Box No
      </th>
      <th
        style={{ width: "120px" }}
           className="table-hader-color table-hadear-height"
      >
        Category
      </th>
      <th
        style={{ width: "120px" }}
           className="table-hader-color table-hadear-height"
      >
        Capacity
      </th>
      <th
        style={{ width: "120px" }}
           className="table-hader-color table-hadear-height"
      >
        Pur Rate
      </th>
      <th
        style={{ width: "120px" }}
           className="table-hader-color table-hadear-height"
      >
        Sale Rate
      </th>
      <th
        style={{ width: "120px" }}
           className="table-hader-color table-hadear-height"
      >
        Stock
      </th>
    </tr>
  </thead>
  <tbody>
    {itemsList.map((row, index) => (
      <tr key={index}>
        <td className="table-row-height">{index + 1}</td>
        <td
          style={{ verticalAlign: "middle" }}
          className="table-row-height"
        >
          {row.itm_cod}
        </td>
        <td
          style={{ verticalAlign: "middle", textAlign: "left" }}
          className="table-row-height"
        >
          {row.itm_dsc}
        </td>
        <td
          style={{ verticalAlign: "middle", textAlign: "center" }}
          className="table-row-height"
        >
          {row.bx_dsc}
        </td>
        <td
          style={{ verticalAlign: "middle" }}
          className="table-row-height"
        >
          {row.cat_dsc}
        </td>
        <td
          style={{ verticalAlign: "middle" }}
          className="table-row-height"
        >
          {row.cap_dsc}
        </td>
        <td
          style={{ verticalAlign: "middle" }}
          className="table-row-height"
        >
          {row.itm_purrat}
        </td>
        <td
          style={{ verticalAlign: "middle" }}
          className="table-row-height"
        >
          {row.itm_salerate}
        </td>
        <td
          style={{ verticalAlign: "middle" }}
          className="table-row-height"
        >
          {row.stock}
        </td>
      </tr>
    ))}
    {Array.from({
      length: Math.max(0, 13 - itemsList.length),
    }).map((_, index) => (
      <tr key={`blank-${index}`} className="table-row-height">
        {Array.from({ length: 9 }).map((_, colIndex) => (
          <td className="table-row-height" key={`blank-${index}-${colIndex}`}>&nbsp;</td>
        ))}
      </tr>
    ))}
  </tbody>
</Table>

        </div>
      </Modal.Body>
      <Modal.Footer>
        {itemsList ? (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
            onClick={console.log("hy")}
          />
        ) : (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ItemListModel;
