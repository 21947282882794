import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  ModalHeader,
  Row,
  Form,
  ModalFooter,
  Button,
  Table,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useTheme } from "../../Utils/ThemeContaxt";
import CashAccountListModel from "../../Transition/SelectListModel/CashAccountListModel";
import CustomerAccountListModel from "../../Transition/SelectListModel/CustomerAccountListModel";
import Bin from "../../../image/bin.jpg";
const CashReceivedVoucherModal = (props) => {
  const { apiLinks } = useTheme();
  const [rows, setRows] = useState([createEmptyRow()]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [cashAccountM, setCashAccountM] = useState(false);
  const [customerAccountM, setCustomerAccountM] = useState(false);
  const [accountDetail, setAccountDetail] = useState("");
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const refs = {
    hadAccCodeRef:useRef([]),
    hadDescriptionRef:useRef([]),
    RemarksRef: useRef(""),
    AccountCodeRef: useRef([]),
    DiscriptionRef: useRef([]),
    AmountRef: useRef([]),
  };

  function createEmptyRow() {
    return {
      AccCode: "",
      description: "",
      Amount: "",
    };
  }
  const clearForm = () => {
    setRows([createEmptyRow()]);
    setAccountDetail('')
    refs.RemarksRef.current.value=''
  
  };
  const addRow = () => {
    let updatedRows = [...rows];
    // Add new empty row
    updatedRows.push(createEmptyRow());
    setRows(updatedRows);
  };
  const deleteRow = (index) => {
    const newTotal = totalAmount - rows[index].Amount;
    const newRows = rows.filter((_, i) => i !== index);
    // rows.splice(index)
    setTotalAmount(newTotal);
    setRows(newRows);
  };
  const handleKeyPress1 = (field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      switch (field) {
        case "AccCode":
          if (refs.hadAccCodeRef.current.value === "") {
            setCashAccountM(true);
            setAccountDetail("");
          } else {
            refs.RemarksRef.current.focus();
          }
          break;

        case "Remarks":
          if (refs.RemarksRef.current.value === "") {
            alert("Please Enter Remarks");
          } else {
            refs.AccountCodeRef.current[0].focus();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleKeyPress = (index, field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      switch (field) {
        case "AccCode":
          if (!rows[index].AccCode) {
            setCurrentRowIndex(index);
            setCustomerAccountM(true);
          } else {
            refs.DiscriptionRef.current[index].focus();
          }
          break;
        case "description":
          refs.AmountRef.current[index].focus();
          break;

        case "Amount":
          if (!rows[index].Amount) {
            alert("Please add an amount.");
          } else {
            if (index === rows.length - 1) {
              addRow();
              setTimeout(
                () => refs.AccountCodeRef.current[rows.length].focus(),
                0
              );
            } else {
              refs.AccountCodeRef.current[index + 1].focus();
            }
          }
          break;
        default:
          break; 
      }
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    setRows(updatedRows);

    let amountTotal = 0;
    rows.forEach((rowData) => {
      const amount = parseFloat(rowData.Amount || 0);
      amountTotal += amount;
    });
    setTotalAmount(amountTotal);
  };

  const getInvoiceNumber = () => {
    axios
      .get(`${apiLinks}/cash-payment-invoice-number`)
      .then(function (response) {
        setInvoiceNumber(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInvoiceNumber();
    refs.hadAccCodeRef.current.focus();
  }, []);
  useEffect(() => {
    // Check if accountDetail is an HTMLDivElement
    if (accountDetail instanceof HTMLDivElement) {
      setAccountDetail(""); // Reset to an empty string if it is an HTML element
    } else {
      refs.hadAccCodeRef.current.value = accountDetail.code ?? "";
      refs.hadDescriptionRef.current.value = accountDetail.description ?? "";
    }
  }, [accountDetail]);

  // useEffect(() => {
  //   // Check if accountDetail is an HTMLDivElement
  //   if (accountDetail instanceof HTMLDivElement) {
  //     setCustomerAccountDetail(""); // Reset to an empty string if it is an HTML element
  //   } else {
  //     refs.AccountCodeRef.current.value = customerAccountDetail.code ?? "";
  //     refs.DiscriptionRef.current.value =
  //       customerAccountDetail.description ?? "";
  //   }
  // }, [customerAccountDetail]);

  const handleSelectItem = (item) => {
    const newRows = [...rows];
    newRows[currentRowIndex] = {
      ...newRows[currentRowIndex],
      AccCode: item.code,
      description: item.description,
    };
    setRows(newRows);
    setCustomerAccountM(false);
    setTimeout(() => refs.AmountRef.current[currentRowIndex].focus(), 0);
  };


  const CashReceiveVoucher = () => {
    axios
      .post(`${apiLinks}/cash-receive-voucher`, {
        hadAccountCode: refs.hadAccCodeRef?.current?.value,
        hadAccountDes: refs.hadDescriptionRef?.current?.value,
        remarks: refs.RemarksRef.current.value,
        totalAmount: totalAmount,
        detail: rows,
      })
      .then(function (response) {
        if (response.data.success) {
          clearForm()
          return alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <div>
      <Modal
        {...props}
        keyboard={false}
        size="lg"
        backdrop="static"
        contentLabel="Account Maintenance Modal"
        ariaHideApp={false}
      >
        <ModalHeader className="model-heders" closeButton>
          Cash Receive Voucher
        </ModalHeader>
        <Modal.Body>
          <div className="account-maintenance-form">
            <Row className="g-0">
              <Col lg={2} md={2} sm={2}>
                <Form.Label className="form-lable">Invoice</Form.Label>
              </Col>
              <Col lg={3} md={5} sm={10}>
                <Form.Control
                  value={invoiceNumber.num}
                  className="hadear-form-invoice-number number-form"
                  autoComplete="none"
                  placeholder="Invoice Number"
                  type="number"
                />
              </Col>
            </Row>
            <Row className="g-0">
              <Col lg={2} md={2} sm={2}>
                <Form.Label className="form-lable">Account</Form.Label>
              </Col>

              <Col lg={3} md={5} sm={10} style={{ width: "120px" }}>
                <Form.Control
                  onKeyDown={(e) => handleKeyPress1("AccCode", e)}
                  ref={(el)=>refs.hadAccCodeRef.current=el}
                  className="hadear-form-account-code-filed number-form"
                  autoComplete="none"
                  placeholder="Account Code"
                />
              </Col>
              <Col lg={6} md={10} sm={10}>
                <Form.Control
                  ref={(el)=>refs.hadDescriptionRef.current=el}
                  disabled
                  className="hadear-form-account-dsc-filed"
                  placeholder="Description"
                ></Form.Control>
              </Col>
            </Row>
            <Row className="g-0">
              <Col lg={2} md={2} sm={2}>
                <Form.Label className="form-lable">Remarks</Form.Label>
              </Col>
              <Col lg={10} md={8} sm={10}>
                <Form.Control
                  ref={(el) => (refs.RemarksRef.current = el)}
                  className="hadear-form-Remarks-payment-voucher"
                  placeholder="Remarks"
                  autoComplete="none"
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                  onKeyDown={(e) => handleKeyPress1("Remarks", e)}
                />
              </Col>
            </Row>
            <div style={{ overflowX: "auto" }}>
              <Table
                maxHeight="20vh"
                style={{ width: "736px" }}
                bordered
                hover
                className="mt-2"
              >
                <thead className="table-hader-color">
                  <tr>
                    <th
                      style={{ width: "20px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      #
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Account
                    </th>
                    <th className="table-hader-color table-hadear-height">
                      Description
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Amount
                    </th>
                    <th
                      style={{ width: "50px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td className="table-row-height">{index + 1}</td>
                      <td className="table-row-height">
                        <Form.Control
                          className="edit-filed-height"
                          placeholder="Account"
                          autoComplete="none"
                          value={row.AccCode}
                          ref={(el) =>
                            (refs.AccountCodeRef.current[index] = el)
                          }
                          onChange={(e) =>
                            handleInputChange(index, "AccCode", e.target.value)
                          }
                          onKeyDown={(e) => handleKeyPress(index, "AccCode", e)}
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          className="edit-filed-height"
                          placeholder="Description"
                          autoComplete="none"
                          value={row.description}
                          ref={(el) =>
                            (refs.DiscriptionRef.current[index] = el)
                          }
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyPress(index, "description", e)
                          }
                        />
                      </td>
                      <td className="table-row-height">
                        <Form.Control
                          className="edit-filed-height number-form"
                          placeholder="Amount"
                          autoComplete="none"
                          type="number"
                          value={row.Amount}
                          ref={(el) => (refs.AmountRef.current[index] = el)}
                          onChange={(e) =>
                            handleInputChange(index, "Amount", e.target.value)
                          }
                          onKeyDown={(e) => handleKeyPress(index, "Amount", e)}
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // width: "50px",
                        }}
                      >
                        <img
                          onClick={
                            rows.length > 1 ? () => deleteRow(index) : undefined
                          }
                          src={Bin}
                          alt="delete"
                          style={{
                            cursor: "pointer",
                            width: "10px",
                            height: "10",
                            opacity: rows.length === 1 ? 0.5 : 1, // Optional: to visually indicate disabled state
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  {Array.from({
                    length: Math.max(0, 6 - rows.length),
                  }).map((_, index) => (
                    <tr key={`blank-${index}`} className="table-row-height">
                      {Array.from({ length: 5 }).map((_, colIndex) => (
                        <td className="table-row-height" key={`blank-${index}-${colIndex}`}>&nbsp;</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Table
                maxHeight="20vh"
                style={{ width: "736px" }}
                bordered
                hover
                className="mt-2"
              >
                <thead className="table-hader-color">
                  <tr>
                    <th
                      style={{ width: "20px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {""}
                    </th>
                    <th
                      style={{ width: "100px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {""}
                    </th>
                    <th className="table-hader-color table-hadear-height">
                      {""}
                    </th>
                    <th
                      style={{ width: "150px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {totalAmount.toFixed(2)}
                    </th>
                    <th
                      style={{ width: "50px" }}
                      className="table-hader-color table-hadear-height"
                    >
                      {""}
                    </th>
                  </tr>
                </thead>
              </Table>
            </div>
          </div>
        </Modal.Body>

        <ModalFooter className="model-fotters">
          <Button onClick={()=>{CashReceiveVoucher()}}>Save</Button>
        </ModalFooter>
      </Modal>
      {cashAccountM && (
        <CashAccountListModel
          show={cashAccountM}
          onHide={() => setCashAccountM(false)}
          setAccountDetail={setAccountDetail}
        />
      )}
      {customerAccountM && (
        <CustomerAccountListModel
          show={customerAccountM}
          onHide={() => setCustomerAccountM(false)}
          // setCustomerAccountDetail={setCustomerAccountDetail}
          handleSelectItem={handleSelectItem}
        />
      )}
    </div>
  );
};

export default CashReceivedVoucherModal;
