import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



// Create an async thunk for fetching company data
export const ItemListReduser = createAsyncThunk("item-list", async ({apiLinks}) => {
  try {
    const response = await axios.get(`${apiLinks}/items-list`);
   
    return response.data;
  } catch (error) {
    console.error("Error fetching Capacity Data:", error);
    throw error; // Rethrow the error to be caught by the rejected case
  }
});

 
export const ItemListStore = createSlice({
  name: "ItemListStore",
  initialState: {
    isLoading: false,
    data: [],
    isError:false
  },
  extraReducers: (builder) => {
    builder.addCase(ItemListReduser.pending, (state, action) => {
        state.isLoading = true;
    
      });
    builder.addCase(ItemListReduser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data=action.payload
    
    });

    builder.addCase(ItemListReduser.rejected, (state, action) => {
        console.log("Error" ,action.payload);
        state.isError = true;
    
      }); 
  },
});

// export default Company.reducer;
