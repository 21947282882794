import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  ModalHeader,
  Row,
  Form,
  ModalFooter,
  Button,
  Table,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useTheme } from "../../Utils/ThemeContaxt";
import Bin from "../../../image/bin.jpg";
import { CashAccountListReduser } from "../Reducer/CashAccountListReduser";
import SaleItemsListModel from "../SelectListModel/SaleItemsListModel";
import { useDispatch, useSelector } from "react-redux";
import { ItemListReduser } from "../../Transition/Reducer/ItemListReduser";
import CashAndCustomerAccountListModel from "../../SelectModel/CashAndCustomerAccountListModel";
import { useReactToPrint } from "react-to-print";
import SaleReceipt from "../../Print/SaleReceipt";
import WaitBox from '../../WatingBox/WaitBox'
const ItemSaleModal = (props) => {
  const contentToPrint = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => { 
      setReceiptData({ detail: [] });
      refs.itemCodeRef.current[0].focus();
    },
    removeAfterPrint: true,
  });

  const dispatch = useDispatch();
  const { apiLinks } = useTheme();
  const [showWating, setWatingModal] = useState(false);
  const state = useSelector((state) => state.item_list.data);
  const accountList = useSelector((state) => state.cash_account_list.data);
  const [rows, setRows] = useState([createEmptyRow()]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [saleItemModels, setSaleItemModels] = useState(false);
  const [cashAndCustomerAccountModel, setCashAndCustomerAccountModel] =
    useState(false);
  const [accountDetail, setAccountDetail] = useState("");
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [itemTotalDiscountPercentage, setItemTotalDiscountPercentage] =
    useState(0);
  const [itemTotalDiscountValue, setItemTotalDiscountValue] = useState(0);
  const [itemTotalAmount, setItemTotalAmount] = useState(0);

  const [itemTotalNetAmount, setItemTotalNetAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPayAble, setTotalPayAble] = useState(0);
  const [bottomDiscountPercsentageAmt, setBottomDiscountPercsentageAmt] =
    useState(0);
  const [date, setDate] = useState("");
  const [receiptData, setReceiptData] = useState({ detail: [] });

  useEffect(
    () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are 0-indexed (0-11)
      const day = currentDate.getDate();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();

      setDate(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`);
    },
    [
      /* dependencies */
    ]
  );

  const refs = {
    hadAccCodeRef: useRef([]),
    RemarksRef: useRef([]),
    haddescriptionRef: useRef([]),
    itemCodeRef: useRef([]),
    DiscriptionRef: useRef([]),
    saleRateRef: useRef([]),
    quantityRef: useRef([]),
    disCountRef: useRef([]),
    AmountRef: useRef([]),
    bottomFlateDiscountRef: useRef([]),
    bottomServiceChargeRef: useRef([]),
    bottomDiscountRef: useRef([]),
  };

  function createEmptyRow() {
    return {
      ItemCode: "",
      itemdescription: "",
      itembxcode: "",
      itemdisCount: 0,
      itemdisValue: 0,
      itemcategoryCode: "",
      itemcapacityCode: "",
      itempurchaseRate: "",
      itemsaleRate: 0,
      itemquantity: 0,
      itemTAmount: 0,
      itemNetAmount: 0,
    };
  }
  const clearForm = () => {
    getInvoiceNumber();
    setRows([createEmptyRow()]);
    setAccountDetail("");
    setItemTotalAmount(0);
    setItemTotalNetAmount(0);
    setTotalQuantity(0);
    setTotalPayAble(0);
    refs.bottomDiscountRef.current.value = 0;
    refs.bottomFlateDiscountRef.current.value = 0;
    refs.bottomServiceChargeRef.current.value = 0;
    setBottomDiscountPercsentageAmt(0);

    dispatch(ItemListReduser({ apiLinks }));
  };
  const addRow = () => {
    let updatedRows = [...rows];
    // Add new empty row
    updatedRows.push(createEmptyRow());
    setRows(updatedRows);
  };
  const deleteRow = (index) => {
    const newItemTAmount = itemTotalAmount - rows[index].itemTAmount;
    const newItemNetAmount = itemTotalNetAmount - rows[index].itemNetAmount;
    const newItemTotalQuantity = totalQuantity - rows[index].itemquantity;
    setTotalPayAble(totalPayAble - rows[index].itemNetAmount);
    setItemTotalDiscountPercentage(
      itemTotalDiscountPercentage - rows[index].itemdisCount
    );
    setItemTotalDiscountValue(
      itemTotalDiscountValue - rows[index].itemdisValue
    );
    setItemTotalAmount(newItemTAmount);
    setItemTotalNetAmount(newItemNetAmount);
    setTotalQuantity(newItemTotalQuantity);
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };
  const handleKeyPress1 = (field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      switch (field) {
        case "AccCode":
          if (refs.hadAccCodeRef.current.value === "") {
            setCashAndCustomerAccountModel(true);
            setAccountDetail("");
          } else {
            refs.RemarksRef.current.focus();
          }
          break;
        case "flateDiscount":
          refs.bottomServiceChargeRef.current.focus();
          break;
        case "serviceCharge":
          refs.bottomDiscountRef.current.focus();
          break;

        case "Remarks":
          if (refs.RemarksRef.current.value === "") {
            alert("Please Enter Remarks");
          } else {
            refs.itemCodeRef.current[0].focus();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleKeyPress = (index, field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      switch (field) {
        case "ItemCode":
          if (!rows[index].ItemCode) {
            setCurrentRowIndex(index);
            setSaleItemModels(true);
          } else {
            refs.quantityRef.current[index].focus();
          }
          break;
        // case "description":
        //   refs.quantityRef.current[index].focus();
        //   break;
        case "itemsaleRate":
          refs.quantityRef.current[index].focus();
          break;
        case "itemquantity":
          if (!rows[index].itemquantity) {
            alert("Please add an Quantity.");
          } else {
            refs.disCountRef.current[index].focus();
          }
          break;
        case "itemdisCount":
          if (index === rows.length - 1) {
            addRow();
            setTimeout(() => refs.itemCodeRef.current[rows.length].focus(), 0);
          } else {
            refs.itemCodeRef.current[index + 1].focus();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "ItemCode") {
      const selectedItem = state.find((item) => item.itm_cod === value);
      if (selectedItem) {
        updatedRows[index] = {
          ...updatedRows[index],
          ItemCode: selectedItem.itm_cod,
          itemdescription: selectedItem.itm_dsc,
          itembxcode: selectedItem.itm_location,
          itemdisCount: 0,
          itemdisValue: 0,
          itemcategoryCode: selectedItem.itm_catg_cod,
          itemcapacityCode: selectedItem.itm_cap_cod,
          itempurchaseRate: selectedItem.itm_purrat,
          itemsaleRate: selectedItem.unit_sale,
          itemquantity: 0,
          itemTAmount: 0,
          itemNetAmount: 0,
        };
      } else {
        updatedRows[index] = {
          ...updatedRows[index],
          itemdescription: "",
          itembxcode: "",
          itemdisCount: 0,
          itemdisValue: 0,
          itemcategoryCode: "",
          itemcapacityCode: "",
          itempurchaseRate: "",
          itemsaleRate: 0,
          itemquantity: 0,
          itemTAmount: 0,
          itemNetAmount: 0,
        };
      }
    }

    if (field === "itemquantity") {
      const Iquantity = parseFloat(updatedRows[index].itemquantity || 0);
      const IsaleRate = parseFloat(updatedRows[index].itemsaleRate || 0);
      updatedRows[index].itemTAmount = IsaleRate * Iquantity;
      updatedRows[index].itemNetAmount = IsaleRate * Iquantity;
      updatedRows[index].itemTotalAfterdiscount = IsaleRate * Iquantity;
      updatedRows[index].itemdisCount = 0;
      updatedRows[index].itemdisValue = 0;
      refs.bottomDiscountRef.current.value = 0;
      refs.bottomFlateDiscountRef.current.value = 0;
      refs.bottomServiceChargeRef.current.value = 0;
      setBottomDiscountPercsentageAmt(0);
    }
    if (field === "itemdisCount") {
      const Idiscount = parseFloat(updatedRows[index].itemdisCount || 0);
      const Iitemtotal = parseFloat(updatedRows[index].itemTAmount || 0);
      const IdisCountAmt = (Iitemtotal / 100) * Idiscount;
      const newnetAmt = Iitemtotal - IdisCountAmt;
      updatedRows[index].itemdisValue = IdisCountAmt;
      updatedRows[index].itemNetAmount = newnetAmt;
      refs.bottomDiscountRef.current.value = 0;
      refs.bottomFlateDiscountRef.current.value = 0;
      refs.bottomServiceChargeRef.current.value = 0;
      setBottomDiscountPercsentageAmt(0);
    }

    let itemTotalquantity = 0;
    let itemTotalAmount = 0;
    let itemNetTotalAmount = 0;
    let itemtotalDiscountPercentage = 0;
    let itemtotalDiscountAmt = 0;
    rows.forEach((rowData) => {
      const Iquantity = parseFloat(rowData.itemquantity || 0);
      const ItotalAmount = parseFloat(rowData.itemTAmount || 0);
      const ItotalNetAmount = parseFloat(rowData.itemNetAmount || 0);
      const IdiscountTPercen = parseFloat(rowData.itemdisCount || 0);
      const IdiscountTAmt = parseFloat(rowData.itemdisValue || 0);
      itemTotalquantity += Iquantity;
      itemTotalAmount += ItotalAmount;
      itemNetTotalAmount += ItotalNetAmount;
      itemtotalDiscountPercentage += IdiscountTPercen;
      itemtotalDiscountAmt += IdiscountTAmt;
    });
    setTotalQuantity(itemTotalquantity);
    setItemTotalAmount(itemTotalAmount);
    setItemTotalNetAmount(itemNetTotalAmount);
    setTotalPayAble(itemNetTotalAmount);
    setItemTotalDiscountPercentage(itemtotalDiscountPercentage);
    setItemTotalDiscountValue(itemtotalDiscountAmt);
    setRows(updatedRows);
  };
  const handleAccountChange = (field, value) => {
    if (field === "AccCode") {
      const selectedItem = accountList.find(
        (acc) => acc.code === value.target.value
      );

      if (selectedItem) {
        setAccountDetail(selectedItem);
      } else {
        setAccountDetail("");
      }
    }
  };

  const flatediscountChange = (e) => {
    const charge = parseFloat(refs.bottomServiceChargeRef.current.value || 0);
    const flatDiscount = parseFloat(
      refs.bottomFlateDiscountRef.current.value || 0
    );
    const discount = parseFloat(refs.bottomDiscountRef.current.value || 0);
    const calculateDisAmt = (itemTotalNetAmount / 100) * discount;
    setTotalPayAble(
      itemTotalNetAmount + charge - (flatDiscount + calculateDisAmt)
    );
  };
  const handleServiceCharge = (e) => {
    const charge = parseFloat(refs.bottomServiceChargeRef.current.value || 0);
    const flatDiscount = parseFloat(
      refs.bottomFlateDiscountRef.current.value || 0
    );
    const discount = parseFloat(refs.bottomDiscountRef.current.value || 0);
    const calculateDisAmt = (itemTotalNetAmount / 100) * discount;
    setTotalPayAble(
      itemTotalNetAmount + charge - (flatDiscount + calculateDisAmt)
    );
  };

  const bottomDiscountChange = (e) => {
    const charge = parseFloat(refs.bottomServiceChargeRef.current.value || 0);
    const flatDiscount = parseFloat(
      refs.bottomFlateDiscountRef.current.value || 0
    );
    const discount = parseFloat(refs.bottomDiscountRef.current.value || 0);
    const calculateDisAmt = (itemTotalNetAmount / 100) * discount;
    setBottomDiscountPercsentageAmt(calculateDisAmt);
    setTotalPayAble(
      itemTotalNetAmount + charge - (flatDiscount + calculateDisAmt)
    );
  };

  const getInvoiceNumber = () => {
    axios
      .get(`${apiLinks}/sale-invoice-number`)
      .then(function (response) {
        setInvoiceNumber(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInvoiceNumber();
    dispatch(ItemListReduser({ apiLinks }));
    dispatch(CashAccountListReduser({ apiLinks }));
    refs.itemCodeRef.current[0].focus();
    refs.RemarksRef.current.value = "** CASH SALE **";
  }, []);
  useEffect(() => {
    // Check if accountDetail is an HTMLDivElement
    if (accountDetail instanceof HTMLDivElement) {
      setAccountDetail("");
    } else {
      refs.hadAccCodeRef.current.value = accountDetail.code ?? "";
      refs.haddescriptionRef.current.value =
        accountDetail.description ?? "** CASH CUSTOMER **";
    }
  }, [accountDetail]);

  const handleSelectItem = (item) => {
    const newRows = [...rows];
    newRows[currentRowIndex] = {
      ...newRows[currentRowIndex],
      ItemCode: item.itm_cod,
      itemdescription: item.itm_dsc,
      itembxcode: item.itm_location,
      itemdisCount: 0,
      itemdisValue: 0,
      itemcategoryCode: item.itm_catg_cod,
      itemcapacityCode: item.itm_cap_cod,
      itempurchaseRate: item.itm_purrat,
      itemsaleRate: item.unit_sale,
      itemquantity: 0,
      itemNetAmount: 0,
    };
    setRows(newRows);
    setSaleItemModels(false);
    setTimeout(() => refs.quantityRef.current[currentRowIndex].focus(), 0);
  };

  const ItemSale = () => {
    setWatingModal(true);
    axios
      .post(`${apiLinks}/add-item-sale`, {
        AccountCode: refs.hadAccCodeRef?.current?.value,
        AccountDes: refs.haddescriptionRef?.current?.value,
        remarks: refs.RemarksRef.current.value,
        hadTotalDiscountPercentage: itemTotalDiscountPercentage,
        hadTotalDiscountValue: itemTotalDiscountValue,
        haditemTotalAmount: itemTotalAmount,
        haditemafterdiscount: itemTotalNetAmount,
        bottomFlateDiscount: refs.bottomFlateDiscountRef.current.value,
        bottomSevicesCharge: refs.bottomServiceChargeRef.current.value,
        bottomDiscountPercentage: refs.bottomDiscountRef.current.value,
        bottomDiscountValue: bottomDiscountPercsentageAmt,
        hadQuantity: totalQuantity,
        payableAmount: totalPayAble,
        detail: rows,
      })
      .then(function (response) {
        setReceiptData(response.data.receipt);

        if (response.data.success) {
          setWatingModal(false);
          clearForm();

          // return alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (receiptData.detail.length > 0) {
      handlePrint(); // Ensure the receipt is printed after the data is fully updated
    }
  }, [receiptData]);
  return (
    <div>
      <Modal
        {...props}
        keyboard={false}
        maxHeight={105}
        // size="lg"
        fullscreen
        backdrop="static"
        contentLabel="Account Maintenance Modal"
        ariaHideApp={false}
      >
        <ModalHeader className="model-heders" closeButton>
          Item Sale
        </ModalHeader>
        <Modal.Body>
          <div className="account-maintenance-form">
            <Row className="g-0">
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Invoice</Form.Label>
              </Col>
              <Col lg={2} md={5} sm={10}>
                <Form.Control
                  disabled
                  value={invoiceNumber.num}
                  className="hadear-form-invoice-number"
                  autoComplete="none"
                  placeholder="Invoice Number"
                  type="number"
                  style={{ borderRadius: "0" }}
                />
              </Col>
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Date</Form.Label>
              </Col>
              <Col lg={3} md={5} sm={10}>
                <Form.Control
                  disabled
                  value={date}
                  className="hadear-form-date-filed"
                  style={{ borderRadius: "0" }}
                />
              </Col>
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">User</Form.Label>
              </Col>
              <Col lg={2} md={5} sm={10}>
                <Form.Control
                  disabled
                  value={invoiceNumber.num}
                  className="hadear-form-user-filed"
                  autoComplete="none"
                  placeholder="Invoice Number"
                  type="number"
                  style={{ borderRadius: "0" }}
                />
              </Col>
            </Row>
            <Row className="g-0 ">
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Account</Form.Label>
              </Col>

              <Col lg={2} md={5} sm={10} style={{ width: "120px" }}>
                <Form.Control
                  onKeyDown={(e) => handleKeyPress1("AccCode", e)}
                  ref={(el) => (refs.hadAccCodeRef.current = el)}
                  onChange={(e) => handleAccountChange("AccCode", e)}
                  className="hadear-form-account-code-filed"
                  autoComplete="off" // Correct value for disabling autocomplete
                  placeholder="Account Code"
                  style={{ borderRadius: "0" }}
                />
              </Col>
              <Col lg={6} md={10} sm={10}>
                <Form.Control
                  ref={(el) => (refs.haddescriptionRef.current = el)}
                  disabled
                  className="hadear-form-account-dsc-filed"
                  placeholder="Description"
                  style={{ borderRadius: "0" }}
                ></Form.Control>
              </Col>
            </Row>
            <Row className="g-0">
              <Col lg={1} md={2} sm={2}>
                <Form.Label className="form-lable">Remarks</Form.Label>
              </Col>
              <Col lg={10} md={8} sm={10}>
                <Form.Control
                  ref={(el) => (refs.RemarksRef.current = el)}
                  className="hadear-form-Remarks-filed"
                  placeholder="Remarks"
                  autoComplete="none"
                  style={{ borderRadius: "0" }}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                  onKeyDown={(e) => handleKeyPress1("Remarks", e)}
                />
              </Col>
            </Row>
            <div
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <Row>
                <Col lg={1} md={2} sm={2}>
             
              </Col>
               
                <Table
                  maxHeight="20vh"
                  style={{ width: "900px" }}
                  bordered
                  hover
                  className="mt-2"
                >
                  <thead className="table-hader-color">
                    <tr>
                      <th
                        style={{ width: "20px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        #
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        Code
                      </th>
                      <th className="table-hader-color table-hadear-height">
                        Description
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        Sale Rate
                      </th>
                      <th
                        style={{ width: "50px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        Qty
                      </th>
                      <th
                        style={{ width: "50px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        Dis%
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        D Value
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        Amount
                      </th>
                      <th
                        style={{ width: "50px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="table-row-height">{index + 1}</td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            // className="filed-height"
                            placeholder="Code"
                            autoComplete="none"
                            value={row.ItemCode}
                            ref={(el) => (refs.itemCodeRef.current[index] = el)}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "ItemCode",
                                e.target.value
                              )
                            }
                            onKeyDown={(e) =>
                              handleKeyPress(index, "ItemCode", e)
                            }
                          />
                        </td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            // className="filed-height"
                            placeholder="Description"
                            autoComplete="none"
                            disabled
                            value={row.itemdescription}
                            ref={(el) =>
                              (refs.DiscriptionRef.current[index] = el)
                            }
                          />
                        </td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            className=" number-form"
                            placeholder="Sale Rate"
                            disabled
                            autoComplete="none"
                            type="number"
                            value={row.itemsaleRate}
                            ref={(el) => (refs.saleRateRef.current[index] = el)}
                          />
                        </td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            className="number-form"
                            placeholder="Quentity"
                            autoComplete="none"
                            type="number"
                            onFocus={(e) => e.target.select()}
                            value={row.itemquantity}
                            ref={(el) => (refs.quantityRef.current[index] = el)}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "itemquantity",
                                e.target.value
                              )
                            }
                            onKeyDown={(e) =>
                              handleKeyPress(index, "itemquantity", e)
                            }
                          />
                        </td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            className="number-form"
                            placeholder="Discount"
                            autoComplete="none"
                            type="number"
                            onFocus={(e) => e.target.select()}
                            value={row.itemdisCount}
                            ref={(el) => (refs.disCountRef.current[index] = el)}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "itemdisCount",
                                e.target.value
                              )
                            }
                            onKeyDown={(e) =>
                              handleKeyPress(index, "itemdisCount", e)
                            }
                          />
                        </td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            className=" number-form"
                            disabled
                            autoComplete="none"
                            type="number"
                            value={row.itemdisValue.toFixed(2)}
                          />
                        </td>
                        <td className="table-row-height">
                          <Form.Control
                            style={{
                              borderRadius: "0",
                              padding: "0",
                              height: "25px",
                              fontSize: "15px",
                            }}
                            className="number-form"
                            placeholder="Amount"
                            disabled
                            autoComplete="none"
                            type="number"
                            value={row.itemNetAmount.toFixed(2)}
                          />
                        </td>
                        <td
                          className="table-row-height"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // width: "50px",
                          }}
                        >
                          <img
                            onClick={
                              rows.length > 1
                                ? () => deleteRow(index)
                                : undefined
                            }
                            src={Bin}
                            alt="delete"
                            style={{
                              cursor: "pointer",
                              width: "10px",
                              height: "10px",
                              opacity: rows.length === 1 ? 0.5 : 1, // Optional: to visually indicate disabled state
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    {Array.from({
                      length: Math.max(0, 10 - rows.length),
                    }).map((_, index) => (
                      <tr key={`blank-${index}`}>
                        {Array.from({ length: 9 }).map((_, colIndex) => (
                          <td
                            className="table-row-height"
                            key={`blank-${index}-${colIndex}`}
                          >
                            &nbsp;
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <h1>
                {
                  <Form.Control
                    value={totalPayAble.toLocaleString()}
                    style={{
                      boxShadow: "none",
                      border: "none",
                      borderRadius: "0",
                      padding: "0",
                      height: "80px",
                      fontWeight: "boald",
                      fontSize: "25px",
                      color: "green",
                      width: "150px",
                      backgroundColor: "transparent",
                    }}
                    autoComplete="none"
                  />
                }
              </h1>
                </Row>
                <Table
                  maxHeight="20vh"
                  style={{ width: "900px" }}
                  bordered
                  hover
                  className="mt-2"
                >
                  <thead className="table-hader-color">
                    <tr>
                      <th
                        style={{ width: "20px" }}
                        className="table-hader-color table-hadear-height"
                      ></th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      ></th>
                      <th className="table-hader-color table-hadear-height"></th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      ></th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        {totalQuantity}
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        {itemTotalDiscountPercentage}
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        {itemTotalDiscountValue}
                      </th>
                      <th
                        style={{ width: "100px" }}
                        className="table-hader-color table-hadear-height"
                      >
                        {itemTotalNetAmount}
                      </th>
                      <th
                        style={{ width: "50px" }}
                        className="table-hader-color table-hadear-height"
                      ></th>
                    </tr>
                  </thead>
                </Table>

                <div
                  style={{
                    height: "20px",
                    width: "auto",
                    display: "flex",
                  }}
                >
                  <Table maxHeight="20vh" style={{ width: "900px" }}>
                    <tbody>
                      <td
                        style={{
                          height: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "110px",
                        }}
                      >
                        Total Quantity:
                      </td>
                      <td style={{ width: "100px" }}>
                        <Form.Control
                          value={totalQuantity}
                          disabled
                          style={{
                            boxShadow: "none",
                            borderRadius: "0",
                            padding: "0",
                            height: "20px",
                            fontWeight: "boald",
                            fontSize: "12px",
                            color: "black",
                            width: "100px",
                          }}
                          autoComplete="none"
                        />
                      </td>
                      <td
                        style={{
                          height: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                          textAlign: "right",
                        }}
                      >
                        Flate Dis:
                      </td>
                      <td style={{ width: "100px" }}>
                        <Form.Control
                          onChange={(e) => flatediscountChange(e.target.value)}
                          ref={(e) => (refs.bottomFlateDiscountRef.current = e)}
                          onKeyDown={(e) => handleKeyPress1("flateDiscount", e)}
                          onFocus={(e) => e.target.select()}
                          type="number"
                          style={{
                            boxShadow: "none",
                            borderRadius: "0",
                            padding: "0",
                            height: "20px",
                            fontWeight: "boald",
                            fontSize: "12px",
                            color: "black",
                            width: "100px",
                          }}
                          autoComplete="none"
                        />
                      </td>
                      <td
                        style={{
                          height: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                          textAlign: "right",
                        }}
                      >
                        Servic Crg:
                      </td>
                      <td style={{ width: "100px" }}>
                        <Form.Control
                          onChange={(e) => handleServiceCharge(e)}
                          ref={(e) => (refs.bottomServiceChargeRef.current = e)}
                          onKeyDown={(e) => handleKeyPress1("serviceCharge", e)}
                          onFocus={(e) => e.target.select()}
                          type="number"
                          style={{
                            boxShadow: "none",
                            borderRadius: "0",
                            padding: "0",
                            height: "20px",
                            fontWeight: "boald",
                            fontSize: "12px",
                            color: "black",
                            width: "100px",
                          }}
                          autoComplete="none"
                        />
                      </td>
                      <td
                        style={{
                          height: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                          textAlign: "right",
                        }}
                      >
                        Dic %:
                      </td>
                      <td style={{ width: "100px" }}>
                        <Form.Control
                          onChange={(e) => bottomDiscountChange(e)}
                          ref={(e) => (refs.bottomDiscountRef.current = e)}
                          onFocus={(e) => e.target.select()}
                          style={{
                            boxShadow: "none",
                            borderRadius: "0",
                            padding: "0",
                            height: "20px",
                            fontWeight: "boald",
                            fontSize: "12px",
                            color: "black",
                            width: "100px",
                          }}
                          autoComplete="none"
                        />
                      </td>
                    </tbody>
                  </Table>
                </div>
                <div
                  style={{
                    height: "20px",
                    width: "auto",
                    display: "flex",
                  }}
                >
                  <Table maxHeight="20vh" style={{ width: "890px" }}>
                    <tbody>
                      <td>{itemTotalAmount}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          height: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100px",
                          textAlign: "right",
                        }}
                      >
                        Dic Amt:
                      </td>
                      <td style={{ width: "100px" }}>
                        <Form.Control
                          disabled
                          value={bottomDiscountPercsentageAmt}
                          style={{
                            boxShadow: "none",
                            borderRadius: "0",
                            padding: "0",
                            height: "20px",
                            fontWeight: "boald",
                            fontSize: "12px",
                            color: "black",
                            width: "100px",
                          }}
                          autoComplete="none"
                        />
                      </td>
                    </tbody>
                  </Table>
                </div>
              </div>

          <ModalFooter className="model-fotters">
          <Button onClick={ItemSale}>Save</Button>
        </ModalFooter>
            </div>
          </div>
        </Modal.Body>

      
      </Modal>
      {cashAndCustomerAccountModel && (
        <CashAndCustomerAccountListModel
          show={cashAndCustomerAccountModel}
          onHide={() => setCashAndCustomerAccountModel(false)}
          setAccountDetail={setAccountDetail}
        />
      )}
      {saleItemModels && (
        <SaleItemsListModel
          show={saleItemModels}
          onHide={() => setSaleItemModels(false)}
          // setCustomerAccountDetail={setCustomerAccountDetail}
          handleSelectItem={handleSelectItem}
          itemData={state}
        />
      )}
       <WaitBox show={showWating} />
     

      <div style={{ display: "none" }}>
        <SaleReceipt ref={contentToPrint} datas={receiptData} />
      </div>
    </div>
    
  );
};

export default ItemSaleModal;
