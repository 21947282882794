import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import axios from "axios";
import { useTheme } from "../../../Utils/ThemeContaxt";
import printer from "../../../../image/printer.png";
function CustomerListModel(props) {
  const [customerList, setCustomerList] = useState([]);

  const { apiLinks } = useTheme();
  const getChartOfAccount = () => {
    axios
      .get(`${apiLinks}/customer-list`)
      .then(function (response) {
        setCustomerList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getChartOfAccount();
  }, []);

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      // style={customStyles}
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Customer List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ overflowX: "auto", maxHeight: "530px", overflowY: "auto" }}
        >
          <Table style={{ width: "1080px" }} bordered hover className="mt-2">
            <thead className="table-header-color">
              <tr>
                <th
                  style={{ width: "20px" }}
                  className="table-hader-color table-hadear-height"
                >
                  #
                </th>
                <th
                  style={{ width: "60px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Code
                </th>
                <th
                  style={{ width: "180px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Name
                </th>
                <th
                  style={{ width: "180px" }}
                  className="table-hader-color table-hadear-height"
                >
                  F Name
                </th>
                <th
                  style={{ width: "150px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Mobile
                </th>
                <th
                  style={{ width: "150px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Cnic
                </th>
                <th
                  style={{ width: "300px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Address
                </th>
                <th
                  style={{ width: "10px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {customerList.map((row, index) => (
                <tr key={index}>
                  <td className="table-row-height">{index + 1}</td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="table-row-height"
                  >
                    {row.code}
                  </td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.name}
                  </td>

                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.fname}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="table-row-height"
                  >
                    {row.mobile}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="table-row-height"
                  >
                    {row.cnic}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="table-row-height"
                  >
                    {row.address}
                  </td>

                  <td
                    style={{ verticalAlign: "middle" }}
                    className="table-row-height"
                  >
                    {row.status}
                  </td>
                </tr>
              ))}
              {Array.from({
                length: Math.max(0, 13 - customerList.length),
              }).map((_, index) => (
                <tr key={`blank-${index}`} className="table-row-height">
                  {Array.from({ length: 8 }).map((_, colIndex) => (
                    <td className="table-row-height" key={`blank-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {customerList ? (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
            onClick={console.log("hy")}
          />
        ) : (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CustomerListModel;
