import React, { useEffect, useRef, useState, useCallback } from "react";
import { Modal, Row, Col, Form, Table } from "react-bootstrap";
import printer from "../../../image/printer.png";
import { FixedSizeList as List } from "react-window";
import { debounce } from "lodash";

// Memoized TableRow to avoid unnecessary re-renders
const TableRow = React.memo(
  ({ row, index, highlightedRowIndex, handleRowClick }) => (
    <tr
      style={{ fontSize: "12px", borderBottom: "1px solid black" }}
      key={index}
      id={`row-${index}`}
      className={
        highlightedRowIndex === index ? "table-row-highlighted" : "table-row"
      }
      onClick={() => handleRowClick(row, index)}
    >
      <td style={{ fontSize: "15px", border: "1px solid black", width: "30px" }}>
        {index + 1}
      </td>
      <td style={{ fontSize: "15px", border: "1px solid black", width: "120px" }}>
        {row.itm_cod}
      </td>
      <td style={{ fontSize: "15px", border: "1px solid black" }}>
        {row.itm_dsc}
      </td>
      <td style={{ fontSize: "15px", border: "1px solid black", width: "35px" }}>
        {row.stock}
      </td>
    </tr>
  )
);

function SaleItemsListModel(props) {
  const searchRef = useRef();
  const [highlightedRowIndex, setHighlightedRowIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  // Debounced handleSearchChange to reduce the frequency of updates
  const handleSearchChange = debounce((event) => {
    setHighlightedRowIndex(0);
    const searchcapital = event.target.value.toUpperCase();
    setSearchText(searchcapital);
  }, 300); // Adjust the delay as needed

  const handleArrowKeyPress = (direction) => {
    if (props.itemData.length === 0) return;

    let newIndex = highlightedRowIndex;
    if (direction === "up") {
      newIndex = Math.max(0, highlightedRowIndex - 1);
    } else if (direction === "down") {
      newIndex = Math.min(filteredRows.length - 1, highlightedRowIndex + 1);
    }

    if (newIndex < 0) return;
    setHighlightedRowIndex(newIndex);

    const rowElement = document.getElementById(`row-${newIndex}`);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const handleRowClick = (rowData, rowIndex) => {
    props.handleSelectItem(rowData);
  };

  const filteredRows = props.itemData.filter(
    (row) =>
      (row.itm_cod &&
        row.itm_cod.toLowerCase().includes(searchText.toLowerCase())) ||
      (row.itm_dsc &&
        row.itm_dsc.toLowerCase().includes(searchText.toLowerCase()))
  );

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // Virtualized Row Renderer for react-window
  const RowRenderer = useCallback(
    ({ index, style }) => (
      <div style={style}>
        <Table>
          <tbody>
            <TableRow
              row={filteredRows[index]}
              index={index}
              highlightedRowIndex={highlightedRowIndex}
              handleRowClick={handleRowClick}
            />
          </tbody>
        </Table>
      </div>
    ),
    [filteredRows, highlightedRowIndex, handleRowClick]
  );

  return (
    <Modal
      {...props}
      keyboard={false}
      backdrop="static"
      contentLabel="Item List Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Item List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4} xl={{ span: 4 }}>
            <Form.Control
              autoComplete="off"
              type="text"
              className="form-control-employee search"
              style={{
                height: "25px",
                boxShadow: "none",
                margin: "0.5%",
                backgroundColor: "white",
              }}
              name="searchText"
              ref={searchRef}
              placeholder="Search..."
              onChange={handleSearchChange}
              onBlur={() => searchRef.current.focus()} // Re-focus on blur
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const selectedRowData = filteredRows[highlightedRowIndex];
                  handleRowClick(selectedRowData, highlightedRowIndex);
                } else if (e.key === "ArrowUp") {
                  handleArrowKeyPress("up");
                } else if (e.key === "ArrowDown") {
                  handleArrowKeyPress("down");
                }
              }}
            />
          </Col>
        </Row>
        <div
          style={{ overflowX: "auto", maxHeight: "500px", overflowY: "auto" }}
        >
          <Table bordered hover className="mt-2">
            <thead className="table-hader-color">
              <tr>
                <th style={{ width: "20px" }} className="table-hader-color table-hadear-height">
                  #
                </th>
                <th style={{ width: "70px" }} className="table-hader-color table-hadear-height">
                  Code
                </th>
                <th className="table-hader-color table-hadear-height">
                  Description
                </th>
                <th style={{ width: "70px" }} className="table-hader-color table-hadear-height">
                  Stock
                </th>
              </tr>
            </thead>
          </Table>
          <List
            height={350}
            itemCount={filteredRows.length}
            itemSize={30}
            width={460}
          >
            {RowRenderer}
          </List>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <img
          src={printer}
          alt="Print"
          style={{ width: "35px", height: "20px", cursor: "pointer" }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default SaleItemsListModel;
