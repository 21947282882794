import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react';
import AccountMaintenanceModals from '../File/Model/AccountMaintenanceModal';
import CustomerMaintenanceModal from '../File/Model/CustomerMaintenanceModal';
import ItemMaintenanceModal from '../File/Model/ItemMaintenanceModal';
import CashPaymentVoucherModal from '../Transition/Model/CashPaymentVoucherModal';
import ChartOfAccountModel from '../Reports/Model/List/ChartOfAccountModel';
import CustomerListModel from '../Reports/Model/List/CustomerListModel';
import CategoryListModel from '../Reports/Model/List/CategoryListModel';
import CapacityListModel from '../Reports/Model/List/CapacityListModel';
import ItemListModel from '../Reports/Model/List/ItemListModel';
import ItemSaleModal from '../Transition/Model/ItemSaleModal';
import SupplierMaintenanceModal from '../File/Model/SupplierMaintenanceModal';
import SupplierListModel from '../Reports/Model/List/SupplierListModel';
import ItemPurchaseModal from '../Transition/Model/ItemPurchaseModal';
import CashReceivedVoucherModal from '../Transition/Model/CashReceivedVoucherModal';
import GeneralLedgerModel from '../Reports/Model/Ledger/GeneralLedgerModel';
import SupplierLedgerModel from '../Reports/Model/Ledger/SupplierLedgerModel';
import CustomerLedgerModel from '../Reports/Model/Ledger/CustomerLedgerModel';
import DailyCashBookModel from '../Reports/Model/DailyReports/DailyCashBookModel';
import DailySaleReportModel from '../Reports/Model/DailyReports/DailySaleReportModel';
import DailyPurchaaseReportModel from '../Reports/Model/DailyReports/DailyPurchaaseReportModel';
import LocationListModel from '../Reports/Model/List/LocationListModel';
function TopMenu() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customerIsOpen, setCustomerModalIsOpen] = useState(false);
  const [itemIsOpen, setItemModalIsOpen] = useState(false);
  const [cashPaymentVoucherIsOpen, setCashPaymentVoucherModalIsOpen] = useState(false);
  const [cashReceivedVoucherIsOpen, setCashReceivedModalIsOpen] = useState(false);
  const [chartOfAccountIsOpen, setChartOfAccountModalIsOpen] = useState(false);
  const [customerListIsOpen, setCustomerListModalIsOpen] = useState(false);
  const [locationListIsOpen, setLocationLsitIsOpen] = useState(false);
  const [categoryListIsOpen, setCategoryLsitIsOpen] = useState(false);
  const [capacityListIsOpen, setCapacityLsitIsOpen] = useState(false);
  const [itemsListIsOpen, setItemsLsitIsOpen] = useState(false);
  const [itemSaleIsOpen, setItemSaleIsOpen] = useState(false);
  const [itemPurchaseIsOpen, setItemPurchaseIsOpen] = useState(false);
  const [supplierIsOpen, setSupplierIsOpen] = useState(false);
  const [supplierListIsOpen, setSupplierListIsOpen] = useState(false);
  const [generalLedgerIsOpen, setGeneralLedgerIsOpen] = useState(false);
  const [supplierLedgerIsOpen, setSupplierLedgerIsOpen] = useState(false);
  const [customerLedgerIsOpen, setCustomerLedgerIsOpen] = useState(false);
  const [dailyCashBookIsOpen, setDailyCashBookIsOpen] = useState(false);
  const [dailySaleReportIsOpen, setDailySaleReportIsOpen] = useState(false);
  const [dailyPurchaseReportIsOpen, setDailyPurchseReportIsOpen] = useState(false);
  return (
    <>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">Roomi Baba</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="File" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>{setModalIsOpen(!modalIsOpen)}}>Account Maintinance</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setCustomerModalIsOpen(!customerIsOpen)}}>Customer Maintinance</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setSupplierIsOpen(!supplierIsOpen)}}>Supplier Maintinance</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setItemModalIsOpen(!itemIsOpen)}}>Item MAintinance</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Transition" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={()=>{setCashPaymentVoucherModalIsOpen(!cashPaymentVoucherIsOpen)}}>Cash Payment Voucher</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setCashReceivedModalIsOpen(!cashReceivedVoucherIsOpen)}}>Cash Receive Voucher</NavDropdown.Item>
              
              <NavDropdown.Item onClick={()=>{setItemSaleIsOpen(!itemSaleIsOpen)}}>Item Sale</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setItemPurchaseIsOpen(!itemPurchaseIsOpen)}}>Item Purchase</NavDropdown.Item>
              {/* <NavDropdown.Item >Item MAintinance</NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="Reports" id="basic-nav-dropdown">

            <NavDropdown title="List" id="basic-nav-dropdown"  className="menu-setting dropend">
              <NavDropdown.Item onClick={()=>{setChartOfAccountModalIsOpen(!chartOfAccountIsOpen)}}>Cart Of Account</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setCustomerListModalIsOpen(!customerListIsOpen)}}>Customer List</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setSupplierListIsOpen(!supplierListIsOpen)}}>Supplier List</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setLocationLsitIsOpen(!locationListIsOpen)}}>Box List</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setCategoryLsitIsOpen(!categoryListIsOpen)}}>Category List</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setCapacityLsitIsOpen(!capacityListIsOpen)}}>Capacity List</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setItemsLsitIsOpen(!itemsListIsOpen)}}>Items List</NavDropdown.Item>
              {/* <NavDropdown.Item >Item MAintinance</NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="Ledger" id="basic-nav-dropdown"  className="menu-setting dropend">
              <NavDropdown.Item onClick={()=>{setGeneralLedgerIsOpen(!generalLedgerIsOpen)}}>Gernel Ledger</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setCustomerLedgerIsOpen(!customerLedgerIsOpen)}} >Customer Ledger</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setSupplierLedgerIsOpen(!supplierLedgerIsOpen)}}>Supplier Ledger</NavDropdown.Item>
              <NavDropdown.Item >Item Ledger</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Daily Reports" id="basic-nav-dropdown"  className="menu-setting dropend">
              <NavDropdown.Item onClick={()=>{setDailyCashBookIsOpen(!dailyCashBookIsOpen)}}>Daily Cash Book</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setDailySaleReportIsOpen(!dailySaleReportIsOpen)}} >Daily Sale Report</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{setDailyPurchseReportIsOpen(!dailyPurchaseReportIsOpen)}} >Daily Purchase Report</NavDropdown.Item>
            </NavDropdown>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {modalIsOpen && <AccountMaintenanceModals show={modalIsOpen} onHide={() => setModalIsOpen(!modalIsOpen)} />}
    {customerIsOpen && <CustomerMaintenanceModal show={customerIsOpen} onHide={() => setCustomerModalIsOpen(!customerIsOpen)} />}
    {itemIsOpen && <ItemMaintenanceModal show={itemIsOpen} onHide={() => setItemModalIsOpen(!itemIsOpen)} />}
    {cashPaymentVoucherIsOpen && <CashPaymentVoucherModal show={cashPaymentVoucherIsOpen} onHide={() => setCashPaymentVoucherModalIsOpen(!cashPaymentVoucherIsOpen)} />}
    {cashReceivedVoucherIsOpen && <CashReceivedVoucherModal show={cashReceivedVoucherIsOpen} onHide={() => setCashReceivedModalIsOpen(!cashReceivedVoucherIsOpen)} />}
    {chartOfAccountIsOpen && <ChartOfAccountModel show={chartOfAccountIsOpen} onHide={() => setChartOfAccountModalIsOpen(!chartOfAccountIsOpen)} />}
    {customerListIsOpen && <CustomerListModel show={customerListIsOpen} onHide={() => setCustomerListModalIsOpen(!customerListIsOpen)} />}
    {locationListIsOpen && <LocationListModel show={locationListIsOpen} onHide={() => setLocationLsitIsOpen(!locationListIsOpen)} />}
    {categoryListIsOpen && <CategoryListModel show={categoryListIsOpen} onHide={() => setCategoryLsitIsOpen(!categoryListIsOpen)} />}
    {capacityListIsOpen && <CapacityListModel show={capacityListIsOpen} onHide={() => setCapacityLsitIsOpen(!capacityListIsOpen)} />}
    {itemsListIsOpen && <ItemListModel show={itemsListIsOpen} onHide={() => setItemsLsitIsOpen(!itemsListIsOpen)} />}
    {itemSaleIsOpen && <ItemSaleModal show={itemSaleIsOpen} onHide={() => setItemSaleIsOpen(!itemSaleIsOpen)} />}
    {itemPurchaseIsOpen && <ItemPurchaseModal show={itemPurchaseIsOpen} onHide={() => setItemPurchaseIsOpen(!itemPurchaseIsOpen)} />}
    {supplierIsOpen && <SupplierMaintenanceModal show={supplierIsOpen} onHide={() => setSupplierIsOpen(!supplierIsOpen)} />}
    {supplierListIsOpen && <SupplierListModel show={supplierListIsOpen} onHide={() => setSupplierListIsOpen(!supplierListIsOpen)} />}
    {generalLedgerIsOpen && <GeneralLedgerModel show={generalLedgerIsOpen} onHide={() => setGeneralLedgerIsOpen(!generalLedgerIsOpen)} />}
    {supplierLedgerIsOpen && <SupplierLedgerModel show={supplierLedgerIsOpen} onHide={() => setSupplierLedgerIsOpen(!supplierLedgerIsOpen)} />}
    {customerLedgerIsOpen && <CustomerLedgerModel show={customerLedgerIsOpen} onHide={() => setCustomerLedgerIsOpen(!customerLedgerIsOpen)} />}
    {dailyCashBookIsOpen && <DailyCashBookModel show={dailyCashBookIsOpen} onHide={() => setDailyCashBookIsOpen(!dailyCashBookIsOpen)} />}
    {dailySaleReportIsOpen && <DailySaleReportModel show={dailySaleReportIsOpen} onHide={() => setDailySaleReportIsOpen(!dailySaleReportIsOpen)} />}
    {dailyPurchaseReportIsOpen && <DailyPurchaaseReportModel show={dailyPurchaseReportIsOpen} onHide={() => setDailyPurchseReportIsOpen(!dailyPurchaseReportIsOpen)} />}
   
    </>
  );
}

export default TopMenu;