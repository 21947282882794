import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {

  // const [primaryColor, setPrimaryColor] = useState('#ac1e1e');
  // const [secondaryColor, setSecondaryColor] = useState('white');
  // const [navbarHeight, setNavbarHeight] = useState(30);
  // const [pathHeight, setPathbarHeight] = useState(30);
  const [apiLinks , setapiLinks] = useState('https://xeon.pk/my-project');
  // const [apiLinks , setapiLinks] = useState('http://localhost:3001/my-project');
  const [pharmacyName , setPharmacyName] = useState('AL AHAD CLINIC');
  const [address , setAddress] = useState('Railway Road, Safdarabad.');
  const [mobile , setMobile] = useState('0324-7528449');
  // const [fontFamily, setfontFamily] = useState('Verdana');



  // Define theme-related values
  const theme = {
    // primaryColor,
    // secondaryColor,
    // navbarHeight,
    // pathHeight,
    apiLinks,
    pharmacyName,
    address,
    mobile
    // fontFamily,
  };

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
}
