import React, { useEffect, useRef, useState } from "react";
import { Modal, Table, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "../../Utils/ThemeContaxt";
import printer from "../../../image/printer.png";
import { PurchaseAccountListReduser } from "../Reducer/PurchaseAccountListReduser";

function PurchaseAccountListModel(props) {
  const searchRef = useRef();
  const dispatch = useDispatch();
  const { apiLinks } = useTheme();
  const [highlightedRowIndex, setHighlightedRowIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const firstRow = useRef(null);
  const state = useSelector((state) => state.purchase_account_list.data);

  const handleSearchChange = (event) => {
    setHighlightedRowIndex(0);
    const searchcapital = event.target.value.toUpperCase();
    setSearchText(searchcapital);
  };

  const handleArrowKeyPress = (direction) => {
    if (state.length === 0) return;

    let newIndex = highlightedRowIndex;
    let upindex = highlightedRowIndex - 10;
    let bottomindex = highlightedRowIndex + 10;

    if (direction === "up") {
      const rowElement = document.getElementById(`row-${upindex}`);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
      newIndex = Math.max(-1, highlightedRowIndex - 1);
    } else if (direction === "down") {
      const rowElement = document.getElementById(`row-${bottomindex}`);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
      newIndex = Math.min(filteredRows.length - 1, highlightedRowIndex + 1);
    }
    if (newIndex < 0) {
      return;
    }
    setHighlightedRowIndex(newIndex);
  };
  const handleRowClick = (rowData, rowIndex) => {
    props.onHide(false);

    props.setAccountDetail(rowData);
    // props.handleSelectItem(rowData);
  };

  const filteredRows = state.filter(
    (row) =>
      (row.code && row.code.toLowerCase().includes(searchText.toLowerCase())) ||
      (row.description &&
        row.description.toLowerCase().includes(searchText.toLowerCase()))
  );

  useEffect(() => {
    searchRef.current.focus();
  }, []);
  useEffect(() => {
    // Function to focus the search input
    const handleClick = () => {
      if (searchRef.current) {
        searchRef.current.focus();
      }
    };

    // Add event listener for clicks on the entire document
    document.addEventListener("click", handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    dispatch(PurchaseAccountListReduser({ apiLinks }));
  }, [dispatch, apiLinks]);

  return (
    <Modal
      {...props}
      keyboard={false}
      backdrop="static"
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Cash Account List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4} xl={{ span: 4 }}>
            <Form.Control
              autoComplete="off" // Ensure autocomplete is turned off
              type="text"
              className="form-control-employee search"
              style={{
                height: "25px",
                boxShadow: "none",
                margin: "0.5%",
                backgroundColor: "white",
              }}
              name="searchText"
              ref={searchRef}
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const selectedRowData = filteredRows[highlightedRowIndex];
                  handleRowClick(selectedRowData, highlightedRowIndex);
                
                } else if (e.key === "ArrowUp") {
                  handleArrowKeyPress("up");
                } else if (e.key === "ArrowDown") {
                  handleArrowKeyPress("down");
                } else {
                   
                }
              }}
            />
          </Col>
        </Row>
        <div
          style={{ overflowX: "auto", maxHeight: "520px", overflowY: "auto" }}
        >
          <Table style={{ width: "460px" }} bordered hover className="mt-2">
            <thead className="table-header-color">
              <tr>
                <th
                  style={{ width: "20px" }}
                  className="table-header-color table-header-height"
                >
                  #
                </th>
                <th
                  style={{ width: "70px" }}
                  className="table-header-color table-header-height"
                >
                  Code
                </th>
                <th className="table-header-color table-header-height">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((row, index) => (
                <tr
                  key={index}
                  id={`row-${index}`}
                  className={
                    highlightedRowIndex === index
                      ? " table-row-highlighted"
                      : "table-row"
                  }
                  ref={index === 0 ? firstRow : null}
                >
                  <td
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                    className="table-row-height"
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                    className="table-row-height"
                  >
                    {row.code}
                  </td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.description}
                  </td>
                </tr>
              ))}

              {Array.from({
                length: Math.max(0, 13 - filteredRows.length),
              }).map((_, index) => (
                <tr key={`blank-${index}`} className="table-row-height">
                  {Array.from({ length: 3 }).map((_, colIndex) => (
                    <td key={`blank-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <img
          src={printer}
          alt="Print"
          style={{ width: "35px", height: "20px", cursor: "pointer" }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default PurchaseAccountListModel;
