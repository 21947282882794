import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  ModalHeader,
  Row,
  Form,
  ModalFooter,
  Button,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "../../Utils/ThemeContaxt";
import { CategoryListReduser } from "../../Reports/Reducer/CategoryListReduser";
import { CapacityListReduser } from "../../Reports/Reducer/CapacityListReduser";
import { AddCategoryModel } from "./AddCategoryModel";
import { AddCapacityModel } from "./AddCapacityModel";
import axios from "axios";
import { AddBoxModel } from "./AddBoxModel";
import { LocationListReduser } from "../../Reports/Reducer/LocationListReduser";
import { toTitleCase } from "../../Utils/UtilsFunction";

const ItemMaintenanceModal = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { apiLinks } = useTheme();
  const [addBoxModelIsOpen, setAddBoxModel] = useState(false);
  const [CategoryModelIsOpen, setAddCategoryModel] = useState(false);
  const [CapacityModelIsOpen, setAddCapacityModel] = useState(false);
  const [unitPurchase, setUnitPurchase] = useState(0);
  const [unitSale, setUnitSale] = useState(0);
  const drugCodeRef = useRef("");
  const drugGenericNameRef = useRef("");
  const drugNameRef = useRef("");
  const drugCategoryRef = useRef("");
  const drugCapacityRef = useRef("");
  const drugLocationRef = useRef("");
  const drugPurRef = useRef(0);
  const drugSaleRef = useRef(0);
  const drugPackRef = useRef(0);
  const drugQuantityRef = useRef(0);

  const clearForm = () => {
    drugCodeRef.current.value = "";
    drugGenericNameRef.current.value = "";
    drugNameRef.current.value = "";
    drugCategoryRef.current.value = "";
    drugCapacityRef.current.value = "";
    drugLocationRef.current.value = "";
    drugPackRef.current.value = "";
    drugQuantityRef.current.value = "";
    drugPurRef.current.value = 0;
    drugSaleRef.current.value = 0;
    setUnitPurchase(0);
    setUnitSale(0);
  };

  useEffect(() => {
    dispatch(LocationListReduser({ apiLinks }));
    dispatch(CategoryListReduser({ apiLinks }));
    dispatch(CapacityListReduser({ apiLinks }));
    drugCodeRef.current.focus();
  }, []);

  const handOnChange = (field, e) => {
    if (field === "PackingQ") {
      const packingQ = parseFloat(drugQuantityRef.current.value) || 0;
      const purchaseRate = parseFloat(drugPurRef.current.value) || 0;
      const saleRate = parseFloat(drugSaleRef.current.value) || 0;

      if (packingQ > 0) {
        const unitPurRate = purchaseRate / packingQ;
        const unitsaleRate = saleRate / packingQ;
        setUnitPurchase(unitPurRate.toFixed(2));
        setUnitSale(unitsaleRate.toFixed(2));
      } else {
        setUnitPurchase(0);
        setUnitSale(0);
      }
    }
  };

  const handleKeyPress = (field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      switch (field) {
        case "Code":
          if (drugCodeRef.current.value === "") {
            return alert("Please Enter Code");
          } else {
            drugGenericNameRef.current.focus();
          }
          break;
        case "GName":
          if (drugGenericNameRef.current.value === "") {
            return alert("Please Enter Generic Name");
          } else {
            drugNameRef.current.focus();
          }
          break;
        case "PurRate":
          if (drugPurRef.current.value === "") {
            return alert("Please Enter Purchase Rate");
          } else {
            drugSaleRef.current.focus();
          }
          break;
        case "SaleRate":
          if (drugSaleRef.current.value === "") {
            return alert("Please Enter Sale Rate");
          } else {
            drugPackRef.current.focus();
          }
          break;

        case "Packing":
          if (drugPackRef.current.value === "") {
            return alert("Please Enter Pack");
          } else {
            drugQuantityRef.current.focus();
          }
          break;

        default:
          break;
      }
    }
  };

  const addItem = () => {
    if (drugCodeRef.current.value === "") {
      alert("Please Enter Item Code");
      return;
    }
    if (drugNameRef.current.value === "") {
      alert("Please Enter Item Name");
      return;
    }
    if (drugPurRef.current.value === "") {
      alert("Please Enter Purchase Rate");
      return;
    }
    if (drugSaleRef.current.value === "") {
      alert("Please Enter Sale Rate");
      return;
    }
    axios
      .post(`${apiLinks}/add-item`, {
        itm_cod: drugCodeRef.current.value,
        itm_gnname: drugCodeRef.current.value,
        itm_dsc: drugNameRef.current.value,
        itm_sts: "Yes",
        itm_cap_cod: drugCapacityRef.current.value,
        itm_catg_cod: drugCategoryRef.current.value,
        itm_location: drugLocationRef.current.value,
        itm_pack: drugPackRef.current.value,
        itm_pack_quantity: drugQuantityRef.current.value,
        itm_purrat: drugPurRef.current.value,
        itm_salerate: drugSaleRef.current.value,
        unit_pur: unitPurchase,
        unit_sale: unitSale,
      })
      .then(function (response) {
        if (response.data.success === true) {
          alert(response.data.message);
          clearForm();
        } else {
          alert(response.data.message);
        }
      })

      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <>
      <div>
        <Modal
          {...props}
          size="lg"
          backdrop="static"
          // style={customStyles}
          contentLabel="Account Maintenance Modal"
          ariaHideApp={false}
        >
          <ModalHeader className="model-heders" closeButton>
            Drug Maintenance{" "}
          </ModalHeader>
          <Modal.Body>
            <div className="account-maintenance-form">
              <Row className="g-0">
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Drug Code</Form.Label>
                </Col>
                <Col lg={4} md={10} sm={12}>
                  <Form.Control
                    className="form-border"
                    placeholder="Drug Code"
                    autoComplete="none"
                    ref={drugCodeRef}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase(); // Convert input to title case
                    }}
                    onKeyDown={(e) => handleKeyPress("Code", e)}
                  />
                </Col>
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Generic Name</Form.Label>
                </Col>
                <Col lg={4} md={10} sm={12}>
                  <Form.Control
                    className="form-border"
                    placeholder="Generic Name"
                    autoComplete="none"
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase(); // Convert input to title case
                    }}
                    ref={drugGenericNameRef}
                    onKeyDown={(e) => handleKeyPress("GName", e)}
                  />
                </Col>
              </Row>
              <Row className="mt-2 g-0">
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Drug Name</Form.Label>
                </Col>
                <Col lg={10} md={10} sm={12}>
                  <Form.Control
                    className="form-border"
                    placeholder="Drug Name" 
                    autoComplete="none"
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();// Convert input to title case
                    }}
                    ref={drugNameRef}
                    onKeyDown={(e) => handleKeyPress("DName", e)}
                  />
                </Col>
              </Row>
              {/* <Row className="mt-2">
              <Col className="mt-2" lg={2} md={2} sm={12}>
                <Form.Label className="form-lable">Company</Form.Label>
              </Col>
              <Col lg={8} md={8} sm={10}>
                <Form.Control
                ref={companyRef}
                  className="form-border"
                  as="select"
                  placeholder="Category"
                  // onChange={(e)=>handAccountSelect(e.target.value)}
                >
                  <option value="">Select Company</option>
                  {state.company_list.data.map((row) => {
                    return (
                      <option key={row.com_id} value={row.com_id}>
                        {row.com_dsc}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col lg={2} md={2} sm={2}>
                <Button onClick={()=>setAddCompanyModel(!CompanyModelIsOpen)}>New</Button>
              </Col>
            </Row> */}
              <Row className="mt-2">
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Catetory</Form.Label>
                </Col>
                <Col lg={8} md={8} sm={10} xs={10}>
                  <Form.Control
                    ref={drugCategoryRef}
                    className="form-border"
                    as="select"
                    placeholder="Category"
                    // onChange={(e)=>handAccountSelect(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {state.category_list.data.map((row) => {
                      return (
                        <option key={row.cat_id} value={row.cat_id}>
                          {row.cat_dsc}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <Button
                    onClick={() => setAddCategoryModel(!CategoryModelIsOpen)}
                  >
                    New
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Capacity</Form.Label>
                </Col>
                <Col lg={8} md={8} sm={10} xs={10}>
                  <Form.Control
                    ref={drugCapacityRef}
                    className="form-border"
                    as="select"
                    placeholder="Category"
                    style={{ maxHeight: "60px", overflowX: "auto" }}
                  >
                    <option value="">Select Capacity</option>
                    {state.capacity_list.data.map((row) => {
                      return (
                        <option key={row.cap_id} value={row.cap_id}>
                          {row.cap_dsc}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <Button
                    onClick={() => setAddCapacityModel(!CapacityModelIsOpen)}
                  >
                    New
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Location</Form.Label>
                </Col>
                <Col lg={8} md={8} sm={10} xs={10}>
                  <Form.Control
                    ref={drugLocationRef}
                    className="form-border"
                    as="select"
                    placeholder="Category"
                    style={{ maxHeight: "60px", overflowX: "auto" }}
                  >
                    <option value="">Select Location</option>
                    {state.box_list.data.map((row) => {
                      return (
                        <option key={row.bx_id} value={row.bx_id}>
                          {row.bx_dsc}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <Button onClick={() => setAddBoxModel(!addBoxModelIsOpen)}>
                    New
                  </Button>
                </Col>
              </Row>

              <Row className="mt-2 " style={{ padding: "0" }}>
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Pur Rate</Form.Label>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <Form.Control
                    ref={drugPurRef}
                    className="form-border number-form"
                    autoComplete="none"
                    type="number"
                    placeholder="Purchase Rate"
                    onKeyDown={(e) => handleKeyPress("PurRate", e)}
                  />
                </Col>
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Sale Rate</Form.Label>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <Form.Control
                    ref={drugSaleRef}
                    className="form-border number-form"
                    autoComplete="none"
                    type="number"
                    placeholder="Sale Rate"
                    onKeyDown={(e) => handleKeyPress("SaleRate", e)}
                  />
                </Col>
              </Row>
              <Row className="mt-2 g-0" style={{ padding: "0" }}>
                <Col className="mt-2" lg={2} md={2} sm={12}>
                  <Form.Label className="form-lable">Packing</Form.Label>
                </Col>
                <Col lg={1} md={1} sm={1} xs={4}>
                  <Form.Control
                    ref={drugPackRef}
                    className="number-form"
                    autoComplete="none"
                    type="number"
                    onKeyDown={(e) => handleKeyPress("Packing", e)}
                  />
                </Col>
                <Col
                  style={{ width: "15px" }}
                  className="mt-2"
                  lg={1}
                  md={1}
                  sm={1}
                >
                  <Form.Label className="form-lable">X</Form.Label>
                </Col>
                <Col lg={1} md={1} sm={1} xs={4}>
                  <Form.Control
                    ref={drugQuantityRef}
                    className="number-form"
                    autoComplete="none"
                    type="number"
                    onKeyDown={(e) => handleKeyPress("PackQuantity", e)}
                    onChange={(e) => handOnChange("PackingQ", e)}
                  />
                </Col>
                <Col
                  style={{ width: "90px" }}
                  className="mt-2"
                  lg={2}
                  md={1}
                  sm={1}
                >
                  <Form.Label className="form-lable">Unit Pur</Form.Label>
                </Col>
                <Col lg={2} md={2} sm={2} xs={4}>
                  <Form.Control
                    value={unitPurchase}
                    className="number-form"
                    autoComplete="none"
                  />
                </Col>
                <Col
                  style={{ width: "90px" }}
                  className="mt-2"
                  lg={2}
                  md={1}
                  sm={1}
                >
                  <Form.Label className="form-lable">Unit Sale</Form.Label>
                </Col>
                <Col lg={2} md={2} sm={2} xs={4}>
                  <Form.Control
                    value={unitSale}
                    className="number-form"
                    autoComplete="none"
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <ModalFooter className="model-fotters">
            <Button onClick={addItem}>Save</Button>
          </ModalFooter>
        </Modal>
      </div>
      {addBoxModelIsOpen && (
        <AddBoxModel
          show={addBoxModelIsOpen}
          onHide={() => setAddBoxModel(!addBoxModelIsOpen)}
        />
      )}
      {CategoryModelIsOpen && (
        <AddCategoryModel
          show={CategoryModelIsOpen}
          onHide={() => setAddCategoryModel(!CategoryModelIsOpen)}
        />
      )}
      {CapacityModelIsOpen && (
        <AddCapacityModel
          show={CapacityModelIsOpen}
          onHide={() => setAddCapacityModel(!CapacityModelIsOpen)}
        />
      )}
    </>
  );
};

export default ItemMaintenanceModal;
