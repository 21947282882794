import { useEffect, useState } from "react";
import { Modal, Row, Table, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useTheme } from "../../../Utils/ThemeContaxt";
import printer from "../../../../image/printer.png";
function DailyCashBookModel(props) {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cashBookData, setCashBookData] = useState({payment:[],receive:[]});
  const { apiLinks } = useTheme();

  const maxLength = Math.max(
    (cashBookData.payment || []).length,
    (cashBookData.receive || []).length
  );
  
  const blankRows = Math.max(0, 15 - maxLength);

  const getCashBookData = () => {
    setIsLoading(true)
   
    axios
      .post(`${apiLinks}/daily-cash-book`, {
        date: selectedFromDate
      })
      .then(function (response) {
        setCashBookData(response.data);
        setIsLoading(false)

      })
      .catch(function (error) {
        console.log(error);
      });
  };




  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      // style={customStyles}
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Daily Cash Book</Modal.Title>
      </Modal.Header>
      {/* <Modal.Header>Heloo</Modal.Header> */}
      <Modal.Body>
        <div
          style={{ overflowX: "auto", maxHeight: "560px", overflowY: "auto"}}
        >
          {/* <Row>
            <Col
              
              lg={1}
              md={12}
              sm={12}
              style={{ width: "50px" }}
            >
              <Form.Label className="form-label">Date</Form.Label>
            </Col>

            <Col lg={2} md={12} sm={12}>
              <Form.Control
                selected={selectedFromDate}
                onChange={(date) => setSelectedFromDate(date.target.value)}
                type="date"
                dateFormat="dd-MM-yyyy"
                placeholderText="mm-dd-yyyy"
                id="date-input"
                className="date-input-field date-filed-style"
              />
            </Col>
         

           
            <Col lg={2} md={12} sm={12}>
            <Button
                  style={{ height: "24px",fontSize:'12px' }}
                  className="col-6"
                  onClick={detCashBookData}
                 
                >Submit</Button>
            </Col>


          </Row>  */}
              <div className="my-1">
            <div className="col-12 d-flex mb-1">
              <div className="col-4 d-flex justify-content-start align-items-center">
                <label className="col-3 text-end">
                  <strong>Date: &ensp;&ensp;</strong>
                </label>
                <Form.Control
                selected={selectedFromDate}
                onChange={(date) => setSelectedFromDate(date.target.value)}
                type="date"
                dateFormat="dd-MM-yyyy"
                placeholderText="mm-dd-yyyy"
                id="date-input"
                className="date-input-field date-filed-style"
                style={{width:'150px'}}
              />
              </div>
              <div className="col-4 d-flex justify-content-start align-items-center">
              <Button
                  style={{width:'160px', height: "24px",fontSize:'12px' }}
                  className="col-12"
                  onClick={getCashBookData}
                 
                >Submit</Button>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <label className="col-3 text-end">
                  <strong>Opening: &ensp;&ensp;</strong>
                </label>
                <input
                  style={{width:'180px', height: "24px",alignContent:'left' }}
                  type="text"
                  className="col-9"
                  // onChange={handleStatus}
                  value={cashBookData.opening || 0}
                />
              </div>
            </div>
     
          </div>
          <Table style={{ width: "1080px" }} bordered hover className="mt-2">
            <thead className="table-header-color">
              <tr>
              
                <th
                style={{ width: "460" }}
                  className="table-hader-color table-hadear-height"
                >
                  Receive {" "}
                </th>
                <th
                  style={{ width: "80px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Amount { " "} 
                </th>
                <th
                 style={{ width: "460px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Payment {" "}
                </th>
                <th
                  style={{ width: "80px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Amount {" "}
                </th>
               
                
              </tr>
            </thead>
            <tbody>
            {isLoading ? (
    <>
      <tr> 
        <td colSpan="4" className="text-center">
          {/* <Spinner animation="border" variant="primary" /> */}
        </td>
      </tr>
      {Array.from({ length: Math.max(0, 15 - 3) }).map((_, rowIndex) => (
        <tr key={`blank-${rowIndex}`} style={{ border: '1px solid black' }}>
          {Array.from({ length: 4 }).map((_, colIndex) => (
            <td className="table-row-height" key={`blank-${rowIndex}-${colIndex}`} style={{ border: '1px solid black' }}>
              &nbsp;
            </td>
          ))}
        </tr>
      ))}
    </>
  ) : (
    <>
      {(() => {
        const receiveData = cashBookData.payment || [];
        const paymentData = cashBookData.receive || [];
        const maxLength = Math.max(receiveData.length, paymentData.length);

        return (
          <>
            {Array.from({ length: maxLength }).map((_, i) => { 
              const receiveItem = receiveData[i] || {};
              const paymentItem = paymentData[i] || {};
              return (
                <tr key={`row-${i}`} style={{ fontSize: '12px !important', border: '1px solid black' }}>
                  <td className="text-start table-row-height " style={{border: '1px solid black',verticalAlign: "middle" }}>
                    {receiveItem.dsc || ''}
                  </td>
                  <td className="text-end table-row-height " style={{ border: '1px solid black',verticalAlign: "middle" }}>
                    {receiveItem.crt || ''}
                  </td>
                  <td className="text-start table-row-height " style={{ border: '1px solid black',verticalAlign: "middle" }}>
                    {paymentItem.dsc || ''}
                  </td>
                  <td className="text-end table-row-height " style={{  border: '1px solid black',verticalAlign: "middle" }}>
                    {paymentItem.dbt || ''}
                  </td>
                </tr>
              );
            })}
          </>
        );
      })()}

      {
      
      Array.from({ length: blankRows }).map((_, rowIndex) => (
  <tr key={`blank-${rowIndex}`} style={{ border: '1px solid black' }}>
    {Array.from({ length: 4 }).map((_, colIndex) => (
      <td
        className="table-row-height"
        key={`blank-${rowIndex}-${colIndex}`}
        style={{ border: '1px solid black' }}
      >
        &nbsp;
      </td>
    ))}
  </tr>
))}
    </>
  )}
            </tbody>
          </Table>
          <div
              className="col-12 border-dark border-top"
             
            >
              <input
                type="text"
                value={`Closing Balance:${cashBookData.closing}`}
               className="table-hader-color table-hadear-height"
                disabled
                style={{
                  // ...firstColWidth,
                  width: "37%",
                  height: "24px",
              
                  fontWeight: "bold",
                }}
              />
              <input
                type="text"
                value={ cashBookData.totalPay}
                className="table-hader-color table-hadear-height"
                disabled
                style={{
                  // ...secondColWidth,
                  width: "12%",
                  height: "24px",
             
                  fontWeight: "bold",
                }}
              />
              <input
                type="text"
                // value={totalEnteries}
                className="table-hader-color table-hadear-height"
                disabled
                style={{
                  // ...secondColWidth,
                  width: "37%",
                  height: "24px",
           
                  fontWeight: "bold",
                }}
              />
              <input
                type="text"
                value={cashBookData.totalReceive}
               className="table-hader-color table-hadear-height"
                disabled
                style={{
                  // ...thirdColWidth,
                  width: "12%",
                  height: "24px",
               
                  fontWeight: "bold",
                }}
              />
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {cashBookData ? (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
            onClick={console.log("hy")}
          />
        ) : (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DailyCashBookModel;
