import { useTheme } from "../../Utils/ThemeContaxt";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { CapacityListReduser } from "../../Reports/Reducer/CapacityListReduser";

export function AddCapacityModel(props) {
  const dispatch = useDispatch();
  const { apiLinks } = useTheme();

  const dscRef = useRef();

  const addCapacity = () => {
    if (dscRef.current.value === "") {
      alert("Value is empty");
      return;
    }
    axios
      .post(`${apiLinks}/add-capacity`, {
        cap_dsc: dscRef.current.value,
        cap_sts: "Yes",
      })
      .then(function (response) {
        if (response.data.success) {
          dscRef.current.value = "";
          dispatch(CapacityListReduser({ apiLinks }));
        } else {
          alert(response.data.message);
        }
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Capacity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="account-maintenance-form">
          <Form.Group>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Form.Label>Category</Form.Label>
              </Col>

              <Col lg={12} md={12} sm={12}>
                <Form.Control
                  ref={dscRef}
                  autoComplete="none"
                  placeholder="Category Name"
                />
              </Col>
            </Row>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ height: "60px" }}>
        <Button onClick={addCapacity}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
