import React, { forwardRef } from "react";
import { useTheme } from "../Utils/ThemeContaxt"; // Make sure the path is correct
import { Col, Container, Row } from "react-bootstrap";

const SaleReceipt = forwardRef(({ datas }, ref) => {
  const { pharmacyName, address, mobile } = useTheme();

  return (
    <div
      id="receipt-container"
      ref={ref}
      style={{
        fontSize: "10px", // Adjusted font size
        fontFamily: "'Courier New', monospace",

        width: "280px", // Adjusted width to fit 3-inch thermal printer
        margin: "0 auto",
        padding: "1px",
      }}
    >
      <div
        id="receipt"
        style={{
          textAlign: "center",
          borderBottom: "1px solid #000",
        }}
      >
        <div
          style={{
            borderBottom: "2px solid #FFB200",
            marginBottom: "5px",
          }}
        >
          <span style={{ color: "black", fontWeight: "bold", fontSize:'18px'}}>
            {pharmacyName}
          </span>
        </div>
        <p style={{ margin: 0, color: "black" }}>{address}</p>
        <p style={{ margin: 0, color: "black" }}>{mobile}</p>
        <h3
          style={{
            fontSize: "12px", // Adjusted font size
            margin: "5px 0", // Adjusted margin
            color: "black",
          }}
        >
          <strong>Sales Receipt</strong>
        </h3>

        <Row>
          <Col>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <p style={{ margin: 0, color: "black" }}>
                Invoice:{datas.h_ttrnum}
              </p>
            </div>
          </Col>
          <Col>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p style={{ margin: 0, color: "black" }}>Date: {datas.date}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <p style={{ margin: 0, color: "black" }}>{datas.h_accdsc}</p>
            </div>
          </Col>
          <Col>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p style={{ margin: 0, color: "black"}}>
                {datas.h_time}
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ overflowX: "hidden" }}>
        <table
          style={{
            width: "100%",
            fontFamily: "'Courier New', monospace",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #000",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Qty
              </th>
              <th
                style={{
                  borderBottom: "1px solid #000",
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "black",
                  paddingLeft: "5px",
                }}
              >
                Description
              </th>
              <th
                style={{
                  borderBottom: "1px solid #000",
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "black",
                  width: "30px",
                }}
              >
                Rate
              </th>
              <th
                style={{
                  borderBottom: "1px solid #000",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "black",
                  width: "30px",
                }}
              >
                Dis
              </th>
              <th
                style={{
                  borderBottom: "1px solid #000",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "black",
                  width: "70px",
                }}
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              fontSize: "10px",
              fontFamily: "'Courier New', monospace",
            }}
          >
            {datas.detail.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    textAlign: "center",
                    padding: "0",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {item.dt_sleqnt}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "left",
                    padding: "0",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {item.dt_ttrndsc}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    padding: "0",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {item.dt_salrate}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "0",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {item.dt_itemDiscountAmt}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    padding: "0",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {item.dt_tdbtamt}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Container
        style={{
          borderBottom: "1px solid black",
          margin: "10px 0",
          height: "1px",
        }}
      />

      <Row>
        <Col>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <p style={{ margin: 0, color: "black" }}>
              Total Items:{datas.detail.length}
            </p>
          </div>
        </Col>
        <Col>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <p style={{ margin: 0, color: "black" }}>
              <strong> Grosss Amount: {datas.h_ItemTotalAfterDiscount}</strong>
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          {datas.h_OnTotalDisPercentageAmt > 0 && (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <p style={{ margin: 0, color: "black" }}>
                <strong> Discount %: {datas.h_OnTotalDisPercentageAmt}</strong>
              </p>
            </div>
          )}
        </Col>
        <Col>
          {datas.h_FlateDiscount > 0 && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p style={{ margin: 0, color: "black" }}>
                <strong> Flat Discount: {datas.h_FlateDiscount}</strong>
              </p>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
         
        </Col>
        <Col>
          {datas.h_ServiceChrg > 0 && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p style={{ margin: 0, color: "black" }}>
               Service Charge: {datas.h_ServiceChrg}
              </p>
            </div>
          )}
        </Col>
      </Row>
      <Container
        style={{
          border: "1px solid black",
          padding: "3px 10px",
          width: "75%",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "start" }}>
          <p style={{ margin: 0, color: "black" }}>
            <strong style={{ fontSize: "18px" }}>
              {" "}
              Net Amount: {datas.h_ItemTotalNetAmt + "/-"}
            </strong>
          </p>
        </div>
      </Container>
    </div>
  );
});

export default SaleReceipt;
