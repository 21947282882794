import {useState} from "react";
import { Modal, Row, Table,Col,Form,Button } from "react-bootstrap";
import { useTheme } from "../../../Utils/ThemeContaxt";
import printer from "../../../../image/printer.png";
import axios from "axios";
function DailySaleReportModel(props) {
  const [selecteDate, setDate] = useState("");
  const [saleData, setSaleData] = useState([]);

  const { apiLinks } = useTheme();

  const getSaleData = () => {
    if (!selecteDate) {
      return alert('Please Select Date')
    }

    axios
      .post(`${apiLinks}/daily-sale-report`, {
        date: selecteDate
      })
      .then(function (response) {
        setSaleData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <Modal
      {...props}
      backdrop="static"
      // style={customStyles}
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
      size="lg"
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Daily Sale Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ overflowX: "auto", maxHeight: "520px", overflowY: "auto" }}
        >
       <Row>
            <Col
              
              lg={1}
              md={12}
              sm={12}
              style={{ width: "50px" }}
            >
              <Form.Label className="form-label">From</Form.Label>
            </Col>

            <Col lg={5} md={12} sm={12}>
              <Form.Control
                selected={selecteDate}
                onChange={(date) => setDate(date.target.value)}
                type="date"
                dateFormat="dd-MM-yyyy"
                placeholderText="mm-dd-yyyy"
                id="date-input"
                className="date-input-field date-filed-style"
              />
            </Col>
         
        
       
            <Col lg={2} md={12} sm={12}>
              <Button onClick={getSaleData}>Search</Button>
            </Col>
          </Row> 
          <Table style={{ width: "750px" }} bordered hover className="mt-2">
            <thead className="table-header-color">
              <tr>
                <th
                  style={{ width: "20px" }}
                  className="table-hader-color table-hadear-height"
                >
                  #
                </th>
              
                <th   style={{ width: "120px" }} className="table-hader-color table-hadear-height">
                  Code
                </th>
                <th className="table-hader-color table-hadear-height">
                  Description
                </th>
                <th
                  style={{ width: "70px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Rate
                </th>
                <th
                  style={{ width: "50px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Quantity
                </th>
                <th
                  style={{ width: "100px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {saleData.map((row, index) => (
                <tr key={index}>
                  <td className="table-row-height">{index + 1}</td>

                  <td className="table-row-height">{row.itmcode}</td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.dsc}
                  </td>

                  <td className="table-row-height">{row.salerate}</td>
                  <td className="table-row-height">{row.quantity}</td>
                  <td className="table-row-height">{row.dbt}</td>
                </tr>
              ))}
              {Array.from({
                length: Math.max(0, 13 - saleData.length),
              }).map((_, index) => (
                <tr key={`blank-${index}`} className="table-row-height">
                  {Array.from({ length: 6 }).map((_, colIndex) => (
                    <td key={`blank-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {saleData ? (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        ) : (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DailySaleReportModel;
