import React, { useEffect, useState } from "react";
import { Container,Col,Row,Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // Updated Custom CSS file
import { useTheme } from "../Utils/ThemeContaxt";
import axios from "axios";
const DashboardPage = () => {
  const { apiLinks } = useTheme();
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);

  const todayCashSale = () => {
    axios
      .get(`${apiLinks}/today-total-sale`)
      .then(function (response) {
        setTotalSale(response.data.sale);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const todayTotalPurchase = () => {
    axios
      .get(`${apiLinks}/today-total-purchase`)
      .then(function (response) {
        setTotalPurchase(response.data.purchase);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    todayCashSale();
    todayTotalPurchase();
  }, []);
  const revenueExpenseData = {
    labels: [
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Jan",
    ],
    datasets: [
      {
        label: "Revenue",
        data: [100, 200, 150, 400, 350, 450, 500, 550, 600, 750, 900, 850, 400],
        borderColor: "#42A5F5",
        backgroundColor: "rgba(66, 165, 245, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Expense",
        data: [80, 150, 100, 300, 200, 350, 400, 450, 500, 600, 700, 650, 300],
        borderColor: "#FF6384",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const profitData = {
    labels: [
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Jan",
    ],
    datasets: [
      {
        label: "Profit",
        data: [20, 50, 50, 100, 150, 100, 100, 100, 100, 150, 200, 200, 100],
        borderColor: "#36A2EB",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return (
    <Container fluid className="pharmacy-dashboard">
      <Row className="mb-4">
        <Col md={2}>
          <Card className="dashboard-card red-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-money-bill-wave"></i> Today Total Sale
              </Card.Title>
              <h3 className="display-6">{(totalSale || 0).toLocaleString()}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card className="dashboard-card yellow-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-exclamation-circle"></i> Cash Sale
              </Card.Title>
              <h3 className="display-6">11</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card className="dashboard-card yellow-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-exclamation-circle"></i> Credit Sale
              </Card.Title>
              <h3 className="display-6">11</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card className="dashboard-card blue-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-file-invoice-dollar"></i> Today Purchase
              </Card.Title>
              <h3 className="display-6">{(totalPurchase|| 0).toLocaleString()}</h3>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="dashboard-card green-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-receipt"></i> Cash Purchase
              </Card.Title>
              <h3 className="display-6">-49.00</h3>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="dashboard-card green-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-receipt"></i> Credit Purchase
              </Card.Title>
              <h3 className="display-6">-49.00</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={3}>
          <Card className="dashboard-card teal-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-users"></i> Account Holders
              </Card.Title>
              <h3 className="display-6">14</h3>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="dashboard-card pink-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-piggy-bank"></i> Cash in Bank (GHS)
              </Card.Title>
              <h3 className="display-6">100900.00</h3>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="dashboard-card black-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-credit-card"></i> Expenses This Month (GHS)
              </Card.Title>
              <h3 className="display-6">0.00</h3>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="dashboard-card orange-card">
            <Card.Body>
              <Card.Title>
                <i className="fas fa-shopping-cart"></i> Sales This Month
              </Card.Title>
              <h3 className="display-6">10</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Total revenue vs expense this year (GHS)</Card.Title>
              <Line data={revenueExpenseData} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Overall profit this year (GHS)</Card.Title>
              <Line data={profitData} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardPage;
