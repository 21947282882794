import { useEffect, useRef } from "react";
import {
  Col,
  ModalHeader,
  Row,
  Form,
  ModalFooter,
  Button,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useTheme } from "../../Utils/ThemeContaxt";

const SupplierMaintenanceModal = (props) => {
  const { apiLinks } = useTheme();
  const nameRef = useRef("");
  const fnamReeRf = useRef("");
  const mobileRef = useRef("");
  const cnicRef = useRef("");
  const addressRef = useRef("");
  const debitRef = useRef(0);
  const creditRef = useRef(0);

  const handleKeyPress1 = (field, e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      switch (field) {
        case "name":
          fnamReeRf.current.focus();
          break;
        case "fname":
          mobileRef.current.focus();
          break;
        case "mobile":
          cnicRef.current.focus();
          break;
        case "cnic":
          addressRef.current.focus();
          break;
        case "address":
          debitRef.current.focus();
          break;
        case "debit":
          creditRef.current.focus();
          break;
        default:
          break;
      }
    }
  };

  useEffect(()=>{
    nameRef.current.focus()
  },[])

  const AddCustomer = () => {
    axios
      .post(`${apiLinks}/add-supplier`, {
        tcname: nameRef.current.value,
        tcfname: fnamReeRf.current.value,
        tcmobile: mobileRef.current.value,
        tccnic: cnicRef.current.value,
        tcaddress: addressRef.current.value,
        tcdebit: debitRef.current.value || 0,
        tccredit: creditRef.current.value || 0,
      })
      .then(function (response) {
        if (response.data.success) {
          nameRef.current.value = "";
          fnamReeRf.current.value = "";
          mobileRef.current.value = "";
          cnicRef.current.value = "";
          addressRef.current.value = "";
          debitRef.current.value = 0;
          creditRef.current.value = 0;
        }
        return alert(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        // style={customStyles}
        contentLabel="Account Maintenance Modal"
        ariaHideApp={false}
      >
        <ModalHeader className="model-heders" closeButton>
          Supplier Maintenance{" "}
        </ModalHeader>
        <Modal.Body>
          <div className="account-maintenance-form">
            <Row>
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">Name </Form.Label>
              </Col>
              <Col lg={5} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  autoComplete="none"
                  placeholder="Name"
                  ref={nameRef}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                  onKeyDown={(e) => handleKeyPress1("name", e)}
                />
              </Col>
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">Father</Form.Label>
              </Col>
              <Col lg={5} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  autoComplete="none"
                  placeholder="Father Name"
                  ref={fnamReeRf}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                  onKeyDown={(e) => handleKeyPress1("fname", e)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">Mobile</Form.Label>
              </Col>
              <Col lg={5} md={12} sm={12}>
                <Form.Control
                  className="form-border number-form"
                  placeholder="Mobile"
                  autoComplete="none"
                  ref={mobileRef}
                  maxLength={11}
                  type="number"
                  onKeyDown={(e) => handleKeyPress1("mobile", e)}
                />
              </Col>
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">CNIC</Form.Label>
              </Col>
              <Col lg={5} md={12} sm={12}>
                <Form.Control
                  className="form-border number-form"
                  placeholder="CNIC"
                  autoComplete="none"
                  ref={cnicRef}
                  type="number"
                  onKeyDown={(e) => handleKeyPress1("cnic", e)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">Addtess</Form.Label>
              </Col>
              <Col lg={11} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  placeholder="Address"
                  autoComplete="none"
                  ref={addressRef}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                  onKeyDown={(e) => handleKeyPress1("address", e)}
                />
              </Col>
            </Row>
            <Row className="mt-2 " style={{ padding: "0" }}>
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">Debit</Form.Label>
              </Col>
              <Col lg={3} md={12} sm={12}>
                <Form.Control
                  className="form-border number-form"
                  autoComplete="none"
                  ref={debitRef}
                  placeholder="Debit"
                  type="number"
                  onKeyDown={(e) => handleKeyPress1("debit", e)}
                />
              </Col>
              <Col className="mt-2" lg={1} md={12} sm={12}>
                <Form.Label className="form-lable">Credit</Form.Label>
              </Col>
              <Col lg={3} md={12} sm={12}>
                <Form.Control
                  className="form-border number-form"
                  autoComplete="none"
                  ref={creditRef}
                  placeholder="Credit"
                  type="number"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <ModalFooter className="model-fotters">
          <Button onClick={AddCustomer}>Save</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SupplierMaintenanceModal;
