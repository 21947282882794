import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { CategoryListStore } from "../componants/Reports/Reducer/CategoryListReduser";
import { CapacityListStore } from "../componants/Reports/Reducer/CapacityListReduser";
import { CashAccountListStore } from "../componants/Transition/Reducer/CashAccountListReduser";
import { CustomerAccountListStore } from "../componants/Transition/Reducer/CustomerAccountListReduser";
import { ItemListStore } from "../componants/Transition/Reducer/ItemListReduser";
import { PurchaseAccountListStore } from "../componants/Transition/Reducer/PurchaseAccountListReduser";
import { ChartOfAccountListStore } from "../componants/Reports/Reducer/ChartOfAccountListReduser";
import { SupplierListStore } from "../componants/Reports/Reducer/SupplierListReduser";
import { CashAndCustomerAccountListStore } from "../componants/SelectModel/Reduser/CashAndCustomerAccountListReduser";
import {CustomerListStore } from "../componants/Reports/Reducer/CustomerListReduser";
import { LocationListStore } from "../componants/Reports/Reducer/LocationListReduser";



export const Store = configureStore({
  reducer: {
    box_list: LocationListStore.reducer,
    category_list: CategoryListStore.reducer,
    capacity_list: CapacityListStore.reducer,
    customer_list: CustomerListStore.reducer,
    cash_account_list: CashAccountListStore.reducer,
    customer_account_list: CustomerAccountListStore.reducer,
    item_list: ItemListStore.reducer,
    purchase_account_list: PurchaseAccountListStore.reducer,
    chart_of_account_list: ChartOfAccountListStore.reducer,
    supplier_account_list: SupplierListStore.reducer,
    cash_and_customer_account_list: CashAndCustomerAccountListStore.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
 

