import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { CapacityListReduser } from "../../Reducer/CapacityListReduser";
import { useTheme } from "../../../Utils/ThemeContaxt";
import printer from "../../../../image/printer.png";
import { useSelector, useDispatch } from "react-redux";
function CapacityListModel(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.capacity_list.data);

  const { apiLinks } = useTheme();

  useEffect(() => {
    dispatch(CapacityListReduser({ apiLinks }));
  }, []);

  return (
    <Modal
      {...props}
      backdrop="static"
      // style={customStyles}
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>Capacity List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ overflowX: "auto", maxHeight: "520px", overflowY: "auto" }}
        >
          <Table style={{ width: "460px" }} bordered hover className="mt-2">
            <thead className="table-header-color">
              <tr>
                <th
                  style={{ width: "20px" }}
                  className="table-hader-color table-hadear-height"
                >
                  #
                </th>

                <th className="table-hader-color table-hadear-height">
                  Description
                </th>
                <th
                  style={{ width: "10px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {state.map((row, index) => (
                <tr key={index}>
                  <td className="table-row-height">{index + 1}</td>

                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.cap_dsc}
                  </td>

                  <td className="table-row-height">{row.cap_sts}</td>
                </tr>
              ))}
              {Array.from({
                length: Math.max(0, 13 - state.length),
              }).map((_, index) => (
                <tr key={`blank-${index}`} className="table-row-height">
                  {Array.from({ length: 3 }).map((_, colIndex) => (
                    <td className="table-row-height" key={`blank-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {state ? (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        ) : (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CapacityListModel;
