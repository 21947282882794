import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  ModalHeader,
  Row,
  Form,
  ModalFooter,
  Button,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTheme } from "../../Utils/ThemeContaxt";
import axios from "axios";

const AccountMaintenanceModal = (props) => {
  const { apiLinks } = useTheme();
  const [accountHad, setAccountHad] = useState([]);
  const [accountCode, setAccountCode] = useState("");
  const DscRef = useRef();
  const DebitRef = useRef(0);
  const CreditRef = useRef(0);

  const handAccountSelect = (e) => {
    setAccountCode(e);
  };

  const getAccountHad = () => {
    axios
      .get(`${apiLinks}/account-had`)
      .then(function (response) {
        setAccountHad(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getAccountHad();
  }, []);

  const AddAccount = () => {
    if(!accountCode){
       return alert('Select Account Type Please')
    }
    if(DscRef.current.value===''){
        return alert('Please Enter Account Description')
    }
    axios
      .post(`${apiLinks}/add-account`, {
        code: accountCode,
        acc_dsc: DscRef.current.value,
        debit: DebitRef.current.value || 0,
        credit: CreditRef.current.value || 0,
      })
      .then(function (response) {
        if (response.data.success) {
          DscRef.current.value = "";
          DebitRef.current.value = 0;
          CreditRef.current.value = 0;
        }
        return alert(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        {...props}
        backdrop="static"
        contentLabel="Account Maintenance Modal"
      >
        <ModalHeader className="model-heders" closeButton>
          A/C Maintenance{" "}
        </ModalHeader>
        <Modal.Body>
          <div className="account-maintenance-form">
            <Row>
              <Col className="mt-2" lg={2} md={12} sm={12}>
                <Form.Label className="form-lable">Account</Form.Label>
              </Col>
              <Col lg={10} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  as="select"
                  placeholder="Category"
                  onChange={(e) => handAccountSelect(e.target.value)}
                >
                  <option value="">Select Account</option>
                  {accountHad.map((row) => {
                    return (
                      <option key={row.code} value={row.code}>
                        {row.dsc}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="mt-2" lg={2} md={12} sm={12}>
                <Form.Label className="form-lable">Discription</Form.Label>
              </Col>
              <Col lg={10} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  ref={DscRef}
                  // onKeyDown={(e) => handleEnterKeyPress(Address, e)}
                  placeholder="Discrioption"
                  autoComplete="none"
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2 " style={{ padding: "0" }}>
              <Col className="mt-2" lg={2} md={12} sm={12}>
                <Form.Label className="form-lable">Debit</Form.Label>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  ref={DebitRef}
                  // onKeyDown={(e) => handleEnterKeyPress(Address, e)}
                  placeholder="Debit"
                />
              </Col>
              <Col className="mt-2" lg={2} md={12} sm={12}>
                <Form.Label className="form-lable">Credit</Form.Label>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <Form.Control
                  className="form-border"
                  ref={CreditRef}
                  // onKeyDown={(e) => handleEnterKeyPress(Address, e)}
                  placeholder="Credit"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <ModalFooter className="model-fotters">
          <Button onClick={AddAccount}>Save</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AccountMaintenanceModal;
