
import Modal from 'react-bootstrap/Modal';

function WaitBox(props) {
  return (
    
      
      <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
     
      <Modal.Body>
        <h4>Please Wait.....</h4>
      </Modal.Body>
    
    </Modal>
  );
}

export default WaitBox;