import { useEffect, useState } from "react";
import { Modal, Row, Table, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useTheme } from "../../../Utils/ThemeContaxt";
import printer from "../../../../image/printer.png";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { ChartOfAccountListReduser } from "../../Reducer/ChartOfAccountListReduser";
function GeneralLedgerModel(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chart_of_account_list.data);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setToDate] = useState("");
  const [account, setAccount] = useState("");
  const [ledgerData, setLedgerData] = useState({ladger:[]});

  const { apiLinks } = useTheme();
  const handleAccountChange = (selectedOption) => {
    setAccount(selectedOption);
  };


  const getLedgerData = () => {
    if (!selectedFromDate || !selectedToDate || !account) {
      return alert('Please Select All Value')
    }
    if (selectedFromDate > selectedToDate) {
      alert('From Date should be less than To Date');
      return;
  }
    axios
      .post(`${apiLinks}/general-ledger`, {
        account: account.value,
        startDate: selectedFromDate,
        endDate: selectedToDate,
      })
      .then(function (response) {
        setLedgerData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    dispatch(ChartOfAccountListReduser({ apiLinks }));
  }, []);

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      // style={customStyles}
      contentLabel="Account Maintenance Modal"
      ariaHideApp={false}
    >
      <Modal.Header className="model-heders" closeButton>
        <Modal.Title>General Ledger</Modal.Title>
      </Modal.Header>
      {/* <Modal.Header>Heloo</Modal.Header> */}
      <Modal.Body>
        <div
          style={{ overflowX: "auto", maxHeight: "560px", overflowY: "auto"}}
        >
          <Row>
            <Col
              
              lg={1}
              md={12}
              sm={12}
              style={{ width: "50px" }}
            >
              <Form.Label className="form-label">From</Form.Label>
            </Col>

            <Col lg={2} md={12} sm={12}>
              <Form.Control
                selected={selectedFromDate}
                onChange={(date) => setSelectedFromDate(date.target.value)}
                type="date"
                dateFormat="dd-MM-yyyy"
                placeholderText="mm-dd-yyyy"
                id="date-input"
                className="date-input-field date-filed-style"
              />
            </Col>
            <Col
              
              lg={1}
              md={12}
              sm={12}
              style={{ width: "50px" }}
            >
              <Form.Label className="form-label">To</Form.Label>
            </Col>

            <Col lg={2} md={12} sm={12}>
              <Form.Control
                selected={selectedToDate}
                onChange={(date) => setToDate(date.target.value)}
                type="date"
                dateFormat="dd-MM-yyyy"
                placeholderText="mm-dd-yyyy"
                id="date-input"
                className="date-input-field date-filed-style"
              />
            </Col>
            <Col  lg={1} md={12} sm={12}>
              <Form.Label className="form-label">Account</Form.Label>
            </Col>
            <Col lg={3} md={12} sm={12}>
              <Select
                onChange={(e) => handleAccountChange(e)}
                className="custom-select-container"
                classNamePrefix="custom-select"
                options={state.map((acc) => ({
                  value: acc.code,
                  label: acc.code + " - " + acc.description,
                }))}
                isClearable
                isSearchable
                placeholder="Search or select..."
              />
            </Col>
            <Col lg={2} md={12} sm={12}>
              <Button onClick={getLedgerData}>Submit</Button>
            </Col>
          </Row> 
          <Table style={{ width: "1080px" }} bordered hover className="mt-2">
            <thead className="table-header-color">
              <tr>
                <th
                  style={{ width: "20px"}}
                  className="table-hader-color table-hadear-height"
                >
                  #
                </th>
                <th
                  style={{ width: "80px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Date
                </th>
                <th
                  style={{ width: "80px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Invoice
                </th>
                <th
                  
                  className="table-hader-color table-hadear-height"
                >
                  Description
                </th>
                <th
                  style={{ width: "110px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Debit
                </th>
                <th
                  style={{ width: "110px" }}
                  className="table-hader-color table-hadear-height"
                >
                  Credit
                </th>
                <th
                  style={{ width: "110px" }}
                  className="table-hader-color table-hadear-height "
                >
                  Balance
                </th>
                
              </tr>
            </thead>
            <tbody>
              {ledgerData.ladger.map((row, index) => (
                <tr key={index}>
                  <td className="table-row-height">{index + 1}</td>
                  <td
                    style={{ verticalAlign: "middle"}}
                    className="table-row-height"
                  >
                    {row.dates}
                  </td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.dt_ttrnum+row.dt_ttrntyp}
                  </td>

                  <td
                    style={{ verticalAlign: "middle", textAlign: "left" }}
                    className="table-row-height"
                  >
                    {row.dt_ttrndsc}
                  </td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "right"  }}
                    className="table-row-height"
                  >
                    {row.dt_tdbtamt}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" , textAlign: "right" }}
                    className="table-row-height"
                  >
                    {row.dt_tcrtamt}
                  </td>
                  <td
                    style={{ verticalAlign: "middle", textAlign: "right" }}
                    className="table-row-height"
                  >
                    {row.balance}
                  </td>

                   
                </tr>
              ))}
              {Array.from({
                length: Math.max(0, 13 - ledgerData.ladger.length),
              }).map((_, index) => (
                <tr key={`blank-${index}`} className="table-row-height">
                  {Array.from({ length: 7 }).map((_, colIndex) => (
                    <td className="table-row-height" key={`blank-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {ledgerData ? (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
            onClick={console.log("hy")}
          />
        ) : (
          <img
            src={printer}
            alt="Print"
            style={{ width: "35px", height: "20px", cursor: "pointer" }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default GeneralLedgerModel;
